import * as requestFromServer from "./metaPagesCrud";
import { metaPagesSlice, callTypes } from "./metaPagesSlice";

const { actions } = metaPagesSlice;

export const fetchMetaPages = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findMetaPages(queryParams)
    .then((response) => {
      console.log("inside response")
      const { totalCount, entities } = response.data;
      dispatch(actions.metaPagesFetched({ totalCount, entities }));
    })
    .catch((error) => {
      
      error.clientMessage = "Can't find metaPages";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createMetaPage = (metaPage) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createMetaPage(metaPage)
    .then((response) => {
      const metaPage = response.data;
      dispatch(actions.metaPageCreated({ metaPage }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create metaPage";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchMetaPage = (_id) => (dispatch) => {
  if (!_id) {
    return dispatch(actions.metaPageFetched({ metaPageForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMetaPageById(_id)
    .then((response) => {
      const metaPage = response.data;
      dispatch(actions.metaPageFetched({ metaPageForEdit: metaPage }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find metaPage";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteMetaPage = (_id) => (dispatch) => {
  

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteMetaPage(_id)
    .then((response) => {
      dispatch(actions.metaPageDeleted({ _id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete metaPage";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateMetaPage = (metaPage) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateMetaPage(metaPage)
    .then((response) => {
      const metaPage = response.data;
      
      dispatch(actions.metaPageUpdated({ metaPage }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update metaPage";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteMetaPages = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteMetaPages(ids)
    .then(() => {
      dispatch(actions.metaPagesDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete metaPages";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
