import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

import { DashboardPage1 } from "./pages/DashboardPage";

import { CategoriesPage } from "../app/modules/Dash/pages/articles/articles/CategoriesPage";

import { CategoryEdit } from "../app/modules/Dash/pages/articles/articles/category-edit/CategoryEdit";
import { ExpertsPage } from "./modules/Dash/pages/experts/ExpertsPage";
import { RapportcategoriesPage } from "./modules/Dash/pages/questions_rapport/RapportcategoriesPage";
import { RapportcategoryEdit } from "./modules/Dash/pages/questions_rapport/rapportcategory-edit/RapportcategoryEdit";
import { RapportsPage } from "./modules/Dash/pages/rapports/RapportsPage";
import { ReclamationsPage } from "./modules/Dash/pages/reclamations/ReclamationsPage";
import { AssistancesPage } from "./modules/Dash/pages/assistances/AssistancesPage";
import { PacksPage } from "./modules/Dash/pages/packs/PacksPage";
import { AdsPage } from "./modules/Dash/pages/ads/AdsPage";
import { AvisPage } from "./modules/Dash/pages/avis/AvisPage";
import { ClientsPage } from "./modules/Dash/pages/clients/ClientsPage";
import { ReservationsPage } from "./modules/Dash/pages/reservations/ReservationsPage";
import { MarksPage } from "./modules/Dash/pages/marks/MarksPage";
import { MarkEdit } from "./modules/Dash/pages/marks/mark-edit/MarkEdit";
import { ModeratorsPage } from "./modules/Dash/pages/moderators/ModeratorsPage";
import { Aside } from "../_metronic/layout/components/aside/Aside";
import AuthGuard from "./AuthGuard";
import { RecommendationsPage } from "./modules/Dash/pages/recommendations/RecommendationsPage";
import { CarSpecsPage } from "./modules/Dash/pages/carSpecs/CarSpecsPage";
import { SpecTypeEdit } from "./modules/Dash/pages/carSpecs/specTypes-edit/SpecTypeEdit";
import {MatriculesPage} from "./modules/Dash/pages/matricules/MatriculesPage";
import { VoituresPage } from "./modules/Dash/pages/marketplace/VoituresPage";
import { ConfigsPage } from "./modules/Dash/pages/config/ConfigsPage";
import { PartenairesPage } from "./modules/Dash/pages/partenaires/PartenairesPage";
import { MetaPagesPage } from "./modules/Dash/pages/metaPages/MetaPagesPage";

export default function BasePage() {
  // useEffect(() => {
  //   
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect



  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute path="/dashboard" component={DashboardPage1} />
        <ContentRoute path="/dash/moderators" component={ModeratorsPage} />

        <AuthGuard page="articles" path="/dash/articles" component={CategoriesPage} />
        <AuthGuard page="marks" path="/dash/marques" component={MarksPage} />
        <AuthGuard page="articles" path="/dash/categories/:_id/add" component={CategoryEdit} />
        <AuthGuard page="marks" path="/dash/marks/:_id/add" component={MarkEdit} />
        <AuthGuard page="rapport" path="/dash/questionsrapport/categories/:_id/add" component={RapportcategoryEdit} />
        <AuthGuard page="rapport" path="/dash/questions" component={RapportcategoriesPage} />
        <AuthGuard page="experts" path="/dash/experts" component={ExpertsPage} />
        <AuthGuard page="rapports" path="/dash/rapports" component={RapportsPage} />
        <AuthGuard page="reclama" path="/dash/reclamations" component={ReclamationsPage} />
        <AuthGuard page="assist" path="/dash/assistances" component={AssistancesPage} />
        <AuthGuard page="packs" path="/dash/packs" component={PacksPage} />
        <AuthGuard page="ads" path="/dash/ads" component={AdsPage} />
        <AuthGuard page="avis" path="/dash/avis" component={AvisPage} />
        <AuthGuard page="clients" path="/dash/clients" component={ClientsPage} />
        <AuthGuard page="missions" path="/dash/reservations" component={ReservationsPage} />
        <AuthGuard page="recomm" path="/dash/recommendations" component={RecommendationsPage} />
        <AuthGuard page="carSpec" path="/dash/carSpecs" component={CarSpecsPage} />
        <AuthGuard page="carSpec" path="/dash/specs/:_id" component={SpecTypeEdit} />
        <AuthGuard page="matricules" path = "/dash/matricules" component = {MatriculesPage} />
        <AuthGuard page="voitures" path = "/dash/voitures" component = {VoituresPage} />
        <AuthGuard page="config" path = "/dash/configs" component = {ConfigsPage} />
        <AuthGuard page="partenaires" path = "/dash/partenaires" component = {PartenairesPage} />
        <AuthGuard page="metaPages" path = "/dash/metaPages" component = {MetaPagesPage} />
        
        <ContentRoute path="/" component={Home} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}

export const RequireAuthority = ({ authorities, page }) => {
  return (
    authorities.include(page) ?
      <Outlet /> :
      <Redirect exact from="/" to="/dashboard" />
  )

}

export function Home() {
  return (
    <>
      <Aside />
      <div className = "tw-w-full tw-h-full tw-text-center tw-justify-center">
        <div className = "tw-w-full tw-h-full tw-flex tw-flex-row tw-text-center tw-justify-center">
           <h1 className = "tw-text-6xl tw-m-auto">Welcome to Admin Dashboard</h1>
        </div>
      </div>
    </>
  );
}