import axios from "axios";

export const CUSTOMERS_URL = "api/config";

axios.defaults.headers.post["Content-Type"] = "application/json";

export function fetchConfig() {
  return axios.get(
    process.env.REACT_APP_BASE_URL +`/config/` );
}

export function updateConfig(config) {
  return axios.post(
    process.env.REACT_APP_BASE_URL + `/config/`, config,
  );
}
