import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useMatriculesUIContext } from "../MatriculesUIContext";

const selectedMatricules = (entities, ids) => {
  const _matricules = [];
  ids.forEach((id) => {
    const matricule = entities.find((el) => el.id === id);
    if (matricule) {
      _matricules.push(matricule);
    }
  });
  return _matricules;
};

export function MatriculesFetchDialog({ show, onHide }) {
  // MatriculesUI Context
  const matriculesUIContext = useMatriculesUIContext();
  const matriculesUIProps = useMemo(() => {
    return {
      ids: matriculesUIContext.ids,
    };
  }, [matriculesUIContext]);

  // MatriculesRedux state
  const { matricules } = useSelector(
    (state) => ({
      matricules: selectedMatricules(
        state.matricules.entities,
        matriculesUIProps.ids
      ),
    }),
    shallowEqual
  );

  // if matricules weren't selected we should close modal
  useEffect(() => {
    if (!matriculesUIProps.ids || matriculesUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matriculesUIProps.ids]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Fetch selected elements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="timeline timeline-5 mt-3">
          {matricules.map((matricule) => (
            <div
              className="timeline-item align-items-start"
              key={`id${matricule.id}`}
            >
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3" />
              <div className="timeline-badge">
                <i className="fa fa-genderless text-info icon-xxl" />
              </div>
              <div className="timeline-content text-dark-50 mr-5">
                <span className="label label-lg label-light-info label-inline">
                  ID: {matricule.id}
                </span>
                <span className="ml-3">{matricule.name}</span>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-primary btn-elevate"
          >
            Ok
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
