import React from "react";
import { Route } from "react-router-dom";
import { MatriculesLoadingDialog } from "./matricules-loading-dialog/MatriculesLoadingDialog";
import { MatriculeEditDialog } from "./matricule-edit-dialog/MatriculeEditDialog";
import { MatriculesFetchDialog } from "./matricules-fetch-dialog/MatriculesFetchDialog";
import { MatriculeDeleteDialog } from "./matricule-delete-dialog/MatriculeDeleteDialog";
import { MatriculesUIProvider } from "./MatriculesUIContext";

import { MatriculesCard } from "./MatriculesCard";


export function MatriculesPage({ history }) {
  const matriculesUIEvents = {
    newMatriculeButtonClick: () => {
      history.push("/dash/matricules/new");
    },
    openEditMatriculeDialog: (_id) => {
      history.push(`/dash/matricules/${_id}/edit`);
    },
    openDeleteMatriculeDialog: (_id) => {
      history.push(`/dash/matricules/${_id}/delete`);
    },

  };

  return (
    <MatriculesUIProvider matriculesUIEvents={matriculesUIEvents}>
      <MatriculesLoadingDialog />
      <Route path="/dash/matricules/new">
        {({ history, match }) => (
          <MatriculeEditDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/matricules");
            }}
          />
        )}
      </Route>
      <Route path="/dash/matricules/:_id/edit">
        {({ history, match }) => (
          <MatriculeEditDialog
            show={match != null}
            id={match && match.params._id}
            onHide={() => {
              history.push("/dash/matricules");
            }}
          />
        )}
      </Route>
      <Route path="/dash/matricules/:_id/delete">
        {({ history, match }) => (
          <MatriculeDeleteDialog
            show={match != null}
            id={match && match.params._id}
            onHide={() => {
              history.push("/dash/matricules");
            }}
          />
        )}
      </Route>
      <Route path="/dash/matricules/fetch">
        {({ history, match }) => (
          <MatriculesFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/matricules");
            }}
          />
        )}
      </Route>
     
      <MatriculesCard />
    </MatriculesUIProvider>
  );
}
