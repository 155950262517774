import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/carSpecs/carSpecsActions";
import { SpecEditDialogHeader } from "./SpecEditDialogHeader";
import { SpecEditForm } from "./SpecEditForm";
import { useSpecsUIContext } from "../SpecsUIContext";

export function SpecEditDialog() {
  // Specs UI Context
  const specUIContext = useSpecsUIContext();
  const specsUIProps = useMemo(() => {
    return {
      id: specUIContext.selectedId,
      setIds: specUIContext.setIds,
      specTypeId: specUIContext.specTypeId,
      queryParams: specUIContext.queryParams,
      showEditSpecDialog: specUIContext.showEditSpecDialog,
      closeEditSpecDialog: specUIContext.closeEditSpecDialog,
      initSpec: specUIContext.initSpec,
    };
  }, [specUIContext]);

  // Specs Redux state
  const dispatch = useDispatch();
  const { actionsLoading, specForEdit, specTypeForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.specs.actionsLoading,
      specForEdit: state.specs.specForEdit,
      specTypeForEdit: state.specs.specTypeForEdit
    }),
    shallowEqual
  );

  useEffect(() => {
    // server request for getting spec by seleted id
    dispatch(actions.fetchSpec(specsUIProps.id));
  }, [specsUIProps.id, dispatch]);

  const saveSpec = (spec) => {
    
    if (!specsUIProps.id) {
      // server request for creating questions
      dispatch(actions.createSpec(spec)).then(() => {
        // refresh list after deletion
        dispatch(
          actions.findSpecs(
            specsUIProps.queryParams,
            specsUIProps.specTypeId
          )
        ).then(() => {
          // clear selections list
          specsUIProps.setIds([]);
          // closing edit modal
          specsUIProps.closeEditSpecDialog();
        });
      });
    } else {
      // server request for updating questions
      dispatch(actions.updateSpec(spec)).then(() => {
        // refresh list after deletion
        dispatch(
          // refresh list after deletion
          actions.findSpecs(
            specsUIProps.queryParams,
            specsUIProps.specTypeId
          )
        ).then(() => {
          // clear selections list
          specsUIProps.setIds([]);
          // closing edit modal
          specsUIProps.closeEditSpecDialog();
        });
      });
    }
  };

  return (
    <Modal
      size="lg"
      show={specsUIProps.showEditSpecDialog}
      onHide={specsUIProps.closeEditSpecDialog}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <SpecEditDialogHeader id={specsUIProps.id} />
      <SpecEditForm
        saveSpec={saveSpec}
        actionsLoading={actionsLoading}
        spec={specForEdit || specsUIProps.initSpec}
        onHide={specsUIProps.closeEditSpecDialog}
        specTypeForEdit={specTypeForEdit}
      />
    </Modal>
  );
}
