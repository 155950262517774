import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";

export function getPartenaireById(partenaireId) {
  return axios.get(
    process.env.REACT_APP_BASE_URL + `/partenaire/${partenaireId}`
  );
}

export function findPartenaires(queryParams) {
  return axios.post(
    process.env.REACT_APP_BASE_URL + "/partenaire/paginate",
    JSON.stringify(queryParams)
  );
}

export function updatePartenaire(partenaire) {
  return axios.put(
    process.env.REACT_APP_BASE_URL + `/partenaire/${partenaire.get("_id")}`,
    partenaire, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
}

export function createPartenaire(partenaire) {
  return axios.post(
    process.env.REACT_APP_BASE_URL + `/partenaire`,
    partenaire, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
}

export function deletePartenaire(partenaireId) {
  return axios.delete(
    process.env.REACT_APP_BASE_URL + `/partenaire/${partenaireId}`
  );
}

