import React, { useState, useCallback, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../../_metronic/_partials/controls";
import { useDropzone } from 'react-dropzone';

export function PartenaireEditForm({
  savePartenaire,
  partenaire,
  actionsLoading,
  onHide
}) {
  const PartenaireEditSchema = Yup.object().shape({
    name: Yup.string()
      .required("Le champ est obligatoire")
  });

  const [file, setFile] = useState(partenaire?.logo ? { preview: partenaire.logo } : null);
  useEffect(() => {
    if (partenaire?.logo && !file) {
      setFile({ preview: partenaire.logo });
    }}, [partenaire]);
  const [error, setError] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length) {
      const newFile = acceptedFiles[0];
      newFile.preview = URL.createObjectURL(newFile);
      setFile(newFile);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    maxSize: 1024 * 1024 * 10,
    onDrop
  });

  useEffect(() => {
    return () => {
      if (file && file.preview && file.preview.startsWith("blob:")) {
        URL.revokeObjectURL(file.preview);
      }
    };
  }, [file]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ name: partenaire?.name || '' }}
        validationSchema={PartenaireEditSchema}
        onSubmit={(values) => {
          if (!file) {
            setError(true);
          } else {
            values.files = file; // Assuming only one file
            savePartenaire(values);
          }
        }}
      >
        {({
          handleSubmit,
          values,
          errors,
          touched
        }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading ? (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              ) : (
                <Form className="form form-label-right">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <Field
                          name="name"
                          component={Input}
                          placeholder="Name"
                          label="Name"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="row pt-4">
                      <div className="col">
                        <div
                          {...getRootProps({
                            className: 'rounded-lg border-4 border-dotted p-10 flex flex-col items-center cursor-pointer'
                          })}
                        >
                          <input {...getInputProps()} />
                          <div className='flex flex-col items-center justify-center gap-4 text-center'>
                            {isDragActive ? (
                              <p className="text-gray-500 text-xl">Drop the file here ...</p>
                            ) : (
                              <p className="text-gray-500 text-xl">Drag 'n' drop a file here, or click to select a file</p>
                            )}
                          </div>
                        </div>
                        {error && <div className="text-red-600 text-center">
                          Vous devez sélectionner un logo
                        </div>}
                        {file && (
                          <div className="mt-4 text-center">
                            <h3 className='title text-lg font-semibold text-neutral-600 mt-10 border-b pb-3'>
                              Accepted File
                            </h3>
                            <img
                              src={file.preview}
                              alt="Selected logo"
                              className="w-full h-full"
                            />
                            <button
                              type='button'
                              className='w-7 h-7 border rounded-full flex justify-center items-center absolute top-0 right-0 hover:bg-white transition-colors'
                              onClick={() => setFile(null)}
                            >
                              <img src="/media/svg/icons/Navigation/Close.svg" alt="Remove" className='w-5 h-5 transition-colors' />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Annuler
              </button>
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate"
              >
                Enregistrer
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
