export const cities = [
  "Ariana",
  "Béja",
  "Ben Arous",
  "Bizerte",
  "Gabès",
  "Gafsa",
  "Jendouba",
  "Kairouan",
  "Kasserine",
  "Kébili",
  "Le Kef",
  "Mahdia",
  "Manouba",
  "Médenine",
  "Monastir",
  "Nabeul",
  "Sfax",
  "Sidi Bouzid",
  "Siliana",
  "Sousse",
  "Tataouine",
  "Tozeur",
  "Zaghouan",
  "Tunis",
];

export const carsList = [
  'Audi',
  'Chery',
  'Citroên',
  'Renault',
  'Honda',
  "Lamborgini",
  'Mercedes',
  'Peugeot',
  'Suzuki',
  'Volvo',
];