import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./ConfigsUIHelpers";

const ConfigsUIContext = createContext();

export function useConfigsUIContext() {
  return useContext(ConfigsUIContext);
}

export const PacksUIConsumer = ConfigsUIContext.Consumer;

export function ConfigsUIProvider({ configsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initConfig = {
    _id: undefined,
    fullName: "",
    tel: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initConfig,
    newConfigButtonClick: configsUIEvents.newConfigButtonClick,
    openEditConfigDialog: configsUIEvents.openEditConfigDialog,
    openDeleteConfigDialog: configsUIEvents.openDeleteConfigDialog,
    openDeleteConfigsDialog: configsUIEvents.openDeleteConfigsDialog,
    openFetchConfigsDialog: configsUIEvents.openFetchConfigsDialog,
    openUpdateConfigsStatusDialog:
      configsUIEvents.openUpdateConfigsStatusDialog,
    openFetchConfigsDetailsDialog:
      configsUIEvents.openFetchConfigsDetailsDialog,
  };

  return (
    <ConfigsUIContext.Provider value={value}>
      {children}
    </ConfigsUIContext.Provider>
  );
}
