export const initialFilter = {
  filter: {
  },
  pageNumber: 1,
  pageSize: 10,
};

export const sizePerPageList = [
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "50", value: 50 },
];


export const defaultSorted = [
  {
    dataField: "id",
    order: "asc",
  },
];