import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/partenaires/partenairesActions";
import { PartenaireEditDialogHeader } from "./PartenaireEditDialogHeader";
import { PartenaireEditForm } from "./PartenaireEditForm";
import { usePartenairesUIContext } from "../PartenairesUIContext";

export function PartenaireEditDialog({ id, show, onHide }) {
  // PartenairesUI Context
  const partenairesUIContext = usePartenairesUIContext();
  const partenairesUIProps = useMemo(() => {
    return {
      initPartenaire: partenairesUIContext.initPartenaire,
    };
  }, [partenairesUIContext]);

  // PartenairesRedux state
  const dispatch = useDispatch();
  const { actionsLoading, partenaireForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.partenaires.actionsLoading,
      partenaireForEdit: state.partenaires.partenaireForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchPartenaire(id));
  }, [id, dispatch]);

  // server request for saving partenaire
  const savePartenaire = (partenaire) => {
    console.log(partenaire);
    let formData = new FormData();
    for (let key in partenaire) {
      formData.append(key, partenaire[key]);
    }
    if (!id) {
      formData.delete("_id");
      dispatch(actions.createPartenaire(formData)).then(() => onHide());
    } else {
      formData.append("_id", id);
      dispatch(actions.updatePartenaire(formData)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <PartenaireEditDialogHeader id={id} />
      <PartenaireEditForm
        savePartenaire={savePartenaire}
        actionsLoading={actionsLoading}
        partenaire={partenaireForEdit || partenairesUIProps.initPartenaire}
        onHide={onHide}
      />
    </Modal>
  );
}
