import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/metaPage/metaPagesActions";
import { MetaPageEditDialogHeader } from "./MetaPageEditDialogHeader";
import { MetaPageEditForm } from "./MetaPageEditForm";
import { useMetaPagesUIContext } from "../MetaPagesUIContext";

export function MetaPageEditDialog({ id, show, onHide }) {
  // MetaPagesUI Context
  const metaPagesUIContext = useMetaPagesUIContext();
  const metaPagesUIProps = useMemo(() => {
    return {
      initMetaPage: metaPagesUIContext.initMetaPage,
    };
  }, [metaPagesUIContext]);

  // MetaPagesRedux state
  const dispatch = useDispatch();
  const { actionsLoading, metaPageForEdit, specTypes, marks } = useSelector(
    (state) => ({
      actionsLoading: state.metaPages.actionsLoading,
      metaPageForEdit: state.metaPages.metaPageForEdit,
      specTypes: state.specs.entities?.reduce((result, specType) => {
        result[specType.name] = specType.specs.map(spec => spec.name);
        return result;
      }, {}),
      marks: state.marks.entities,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchMetaPage(id));
  }, [id, dispatch]);

  // server request for saving ad
  const saveMetaPage = (metaPage) => {
    let formData = new FormData();
    for (let key in metaPage) {
      if (key !== 'files') {
        if (key === 'secondaryImages') {
          formData.append(key, JSON.stringify(metaPage[key]));
        } else {
          formData.append(key, metaPage[key]);
        }
      }
    }
    if (metaPage.files && metaPage.files.length > 0) {
      for (const file of metaPage.files) {
        formData.append('files', file);
      }
    }
    if (!id) {
      formData.delete("_id");
      dispatch(actions.createMetaPage(formData)).then(() => onHide());
    } else {
      dispatch(actions.updateMetaPage(formData)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <MetaPageEditDialogHeader id={id} />
      <MetaPageEditForm
        saveMetaPage={saveMetaPage}
        actionsLoading={actionsLoading}
        metaPage={metaPageForEdit || metaPagesUIProps.initMetaPage}
        onHide={onHide}
      />
    </Modal>
  );
}
