import React from "react";
import { Route } from "react-router-dom";
import { VoituresLoadingDialog } from "./voitures-loading-dialog/VoituresLoadingDialog";
import { VoitureEditDialog } from "./voiture-edit-dialog/VoitureEditDialog";
import { VoituresFetchDialog } from "./voitures-fetch-dialog/VoituresFetchDialog";
import { VoitureDeleteDialog } from "./voiture-delete-dialog/VoitureDeleteDialog";
import { VoituresUIProvider } from "./VoituresUIContext";

import { VoituresCard } from "./VoituresCard";
import { VoituresDeleteDialog } from "./voitures-delete-dialog/VoituresDeleteDialog";

export function VoituresPage({ history }) {
  const voituresUIEvents = {
    newVoitureButtonClick: () => {
      history.push("/dash/voitures/new");
    },
    openEditVoitureDialog: (_id) => {
      history.push(`/dash/voitures/${_id}/edit`);
    },
    openDeleteVoitureDialog: (_id) => {
      history.push(`/dash/voitures/${_id}/cancel`);
    },
    openDeleteVoituresDialog: () => {
      history.push(`/dash/voitures/deleteVoitures`);
    },
  };

  return (
    <VoituresUIProvider voituresUIEvents={voituresUIEvents}>
      <VoituresLoadingDialog />
      <Route path="/dash/voitures/new">
        {({ history, match }) => (
          <VoitureEditDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/voitures");
            }}
          />
        )}
      </Route>
      <Route path="/dash/voitures/:_id/edit">
        {({ history, match }) => (
          <VoitureEditDialog
            show={match != null}
            id={match && match.params._id}
            onHide={() => {
              history.push("/dash/voitures");
            }}
          />
        )}
      </Route>
      <Route path="/dash/voitures/:_id/cancel">
        {({ history, match }) => (
          <VoitureDeleteDialog
            show={match != null}
            id={match && match.params._id}
            onHide={() => {
              history.push("/dash/voitures");
            }}
          />
        )}
      </Route>
      <Route path="/dash/voitures/fetch">
        {({ history, match }) => (
          <VoituresFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/voitures");
            }}
          />
        )}
      </Route>
      <Route path="/dash/voitures/deleteVoitures">
        {({ history, match }) => (
          <VoituresDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/voitures");
            }}
          />
        )}
      </Route>
      <VoituresCard />
    </VoituresUIProvider>
  );
}
