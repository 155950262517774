import * as requestFromServer from './matriculesCrud';
import { matriculesSlice, callTypes } from './matriculesSlice';

const { actions } = matriculesSlice;

export const fetchMatricule = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.matriculeFetched({ matriculeForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMatriculeById(id)
    .then((response) => {
      const matricule = response.data;
      dispatch(actions.matriculeFetched({ matriculeForEdit: matricule }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find matricule";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const deleteMatricule = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteMatricule(id)
    .then((response) => {
      dispatch(actions.matriculeDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete matricule";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const createMatricule = (matriculeForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createMatricule(matriculeForCreation)
    .then((response) => {
      const matricule = response.data;
      dispatch(actions.matriculeCreated({ matricule }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create matricule";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const updateMatricule = (matricule) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateMatricule(matricule)
    .then(() => {
      dispatch(actions.matriculeUpdated({ matricule }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update matricule";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const fetchMatricules = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findMatricules(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.matriculesFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find matricules";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

