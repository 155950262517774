import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";

// CREATE =>  POST: add a new matricule to the server
export function createMatricule(matricule) {
  return axios.post(process.env.REACT_APP_BASE_URL + "/matricule", matricule);
}


export function getMatriculeById(matriculeId) {
  return axios.get(process.env.REACT_APP_BASE_URL + `/matricule/${matriculeId}`);
}

export function findMatricules(queryParams) {
  return axios.post(
    process.env.REACT_APP_BASE_URL + "/matricule/paginate",
    JSON.stringify(queryParams)
  );
}

export function updateMatricule(matricule) {
  return axios.put(
    process.env.REACT_APP_BASE_URL + `/matricule/${matricule._id}`,
    matricule
  );
}

export function deleteMatricule(matriculeId) {
  return axios.delete(process.env.REACT_APP_BASE_URL + `/matricule/${matriculeId}`);
}

