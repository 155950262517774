import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";

export function getMetaPageById(metaPageId) {
  return axios.get(
    process.env.REACT_APP_BASE_URL + `/metapage/id/${metaPageId}`
  );
}

export function findMetaPages(queryParams) {
  return axios.post(
    process.env.REACT_APP_BASE_URL + "/metapage/paginate",
    JSON.stringify(queryParams)
  );
}

export function updateMetaPage(metaPage) {
  return axios.put(
    process.env.REACT_APP_BASE_URL + `/metapage/${metaPage.get("_id")}`,
    metaPage, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
}

export function createMetaPage(metaPage) {
  return axios.post(
    process.env.REACT_APP_BASE_URL + `/metapage`,
    metaPage, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
}

export function deleteMetaPage(metaPageId) {
  return axios.delete(
    process.env.REACT_APP_BASE_URL + `/metapage/${metaPageId}`
  );
}

export function deleteMetaPages(ids) {
  return axios.delete(process.env.REACT_APP_BASE_URL + `/metapage`, {
    data: { ids: ids }
  });
}
