// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/marketplace/voituresActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../VoituresUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useVoituresUIContext } from "../VoituresUIContext";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";

export function VoituresTable() {
  // VoituresUI Context
  const voituresUIContext = useVoituresUIContext();
  const voituresUIProps = useMemo(() => {
    return {
      ids: voituresUIContext.ids,
      setIds: voituresUIContext.setIds,
      queryParams: voituresUIContext.queryParams,
      setQueryParams: voituresUIContext.setQueryParams,
      openEditVoitureDialog: voituresUIContext.openEditVoitureDialog,
      openDeleteVoitureDialog: voituresUIContext.openDeleteVoitureDialog,
      openFetchVoituresDetailsDialog:
        voituresUIContext.openFetchVoituresDetailsDialog,
    };
  }, [voituresUIContext]);

  // Getting curret state of voitures list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.voitures }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // VoituresRedux state
  const dispatch = useDispatch();
  const getValueActive = ({ ad, isActive }) => {
    ad = {
      ...ad,
      isActive,
    };
    
    dispatch(actions.updateVoiture(ad));
  };
  useEffect(() => {
    // clear selections list
    voituresUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchVoitures(voituresUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voituresUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "_id",
      text: "ID",
      sort: false,
      formatter: (cell) => {
        return (
          <div>
            <CopyToClipboard
              text={cell}
              onCopy={() => toast.success("ID Copied to Clipboard")}
            >
              <button className="text-primary font-bold">_id</button>
            </CopyToClipboard>
          </div>
        );
      },
    },
    {
      dataField: "title",
      text: "Title",
      sort: false,
      style: {
        maxWidth: "100px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    {
      dataField: "brand",
      text: "Mark",
      sort: false,
      style: {
        maxWidth: "250px",
      },
    },
    {
      dataField: "modele",
      text: "Model",
      sort: false,
      style: {
        maxWidth: "250px",
      },
    },
    {
      dataField: "carburant",
      text: "Carburant",
      sort: false,
      style: {
        maxWidth: "250px",
      },
    },
    {
      dataField: "cylindree",
      text: "Cylindrée",
      sort: false,
      style: {
        maxWidth: "250px",
      },
    },
    {
      dataField: "carosserie",
      text: "Carosserie",
      sort: false,
      style: {
        maxWidth: "250px",
      },
    },
    {
      dataField: "color",
      text: "Couleur",
      sort: false,
      style: {
        maxWidth: "250px",
      },
    },

    // {
    //   dataField: "tel",
    //   text: "TELEPHONE",
    //   formatter: (cellContent, row) => <a href={`tel:${row.tel}`}>{row.tel}</a>,
    //   sort: false,
    //   style: {
    //     minWidth: "120px",
    //     maxWidth: "250px",
    //   },
    // },
    /*  {
      dataField: "img",
      text: "Image",
      sort: true,
      formatter: columnFormatters.TypeColumnFormatter,
      //sortCaret,
      style: {
        maxWidth: "85px",
      },
    }, */
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditVoitureDialog: voituresUIProps.openEditVoitureDialog,
        openDeleteVoitureDialog: voituresUIProps.openDeleteVoitureDialog,
        getValueActive,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: voituresUIProps.queryParams.pageSize,
    page: voituresUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
  
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  voituresUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: voituresUIProps.ids,
                  setIds: voituresUIProps.setIds,
                })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
