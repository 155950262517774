import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { VoituresFilter } from "./voitures-filter/VoituresFilter";
import { VoituresTable } from "./voitures-table/VoituresTable";
import { VoituresGrouping } from "./voitures-grouping/VoituresGrouping";
import { useVoituresUIContext } from "./VoituresUIContext";

export function VoituresCard() {
  const voituresUIContext = useVoituresUIContext();
  const voituresUIProps = useMemo(() => {
    return {
      ids: voituresUIContext.ids,
      newVoitureButtonClick: voituresUIContext.newVoitureButtonClick,
    };
  }, [voituresUIContext]);

  return (
    <Card>
      <CardHeader title="Voitures">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={voituresUIProps.newVoitureButtonClick}
          >
            Nouveau Voiture
          </button>
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody>
        <VoituresFilter />
        {voituresUIProps.ids.length > 0 && <VoituresGrouping />}
        <VoituresTable />
      </CardBody>
    </Card>
  );
}
