import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import * as Yup from "yup";
import { Input } from '../../../../_partials/controls';
import { actions } from '../../../../../app/modules/Auth/_redux/authRedux';
import { useDispatch } from 'react-redux';
export default function AdminEditDialog({ onHide, user }) {

    const dispatch = useDispatch();

    const UserEditSchema = Yup.object().shape({
        fullName: Yup.string()
            .required("Le champ est obligatoire"),
        email: Yup.string()
            .required("Le champ est obligatoire"),
        tel: Yup.number()
            .required("Le champ est obligatoire"),
        password: Yup.string()
            .min(3, "Minimum 3 symbols")
            .optional(),
        changepassword: Yup.string()
            .optional()
            .when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Password and Confirm Password didn't match"
                ),
            }),
    });

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Modifier User {user.fullName}</Modal.Title>
            </Modal.Header>
            <Formik
                enableReinitialize={true}
                initialValues={{ ...user, password: "", changepassword: "" }}
                validationSchema={UserEditSchema}
                onSubmit={(values) => {
                    values.tel = values.tel.toString()
                    dispatch(actions.updateUser(values))
                    onHide();
                }}
            >
                {({ handleSubmit, setFieldValue }) => (
                    <>
                        <Modal.Body className="overlay overlay-block">
                            <Form className="form form-label-right">
                                <div className="form-group">
                                    <div className="row tw-pt-4 tw-gap-y-5">
                                        <div className="col-lg-6 col-12">
                                            <Field
                                                name="fullName"
                                                component={Input}
                                                placeholder="FullName"
                                                label="FullName"
                                                type="text"
                                            />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <Field
                                                name="tel"
                                                component={Input}
                                                placeholder="Tel"
                                                label="Tel"
                                                type="number"
                                            />
                                        </div>
                                        <div className="col-12">
                                            <Field
                                                name="email"
                                                component={Input}
                                                placeholder="Email"
                                                label="Email"
                                                type="text"
                                            />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <Field
                                                name="password"
                                                component={Input}
                                                placeholder="Password"
                                                label="Password"
                                                type="password"
                                            />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <Field
                                                name="changepassword"
                                                component={Input}
                                                placeholder="Confirm Password"
                                                label="Confirm Password"
                                                type="password"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                onClick={onHide}
                                className="btn btn-light btn-elevate"
                            >
                                Annuler
                            </button>
                            <> </>
                            <button
                                type="submit"
                                onClick={() => handleSubmit()}
                                className="btn btn-primary btn-elevate"
                            >
                                Enregistrer
                            </button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </>
    )
}
