import * as requestFromServer from "./configsCrud";
import { configsSlice, callTypes } from "./configsSlice";

const { actions } = configsSlice;

export const fetchConfig = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchConfig()
    .then((response) => {
      const config = response.data;
      dispatch(actions.configFetched({ entities: config }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find config";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateConfig = (config) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateConfig(config)
    .then((response) => {
      dispatch(actions.configUpdated({ entities: response.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update config";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
