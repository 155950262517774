import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCarSpecsUIContext } from "../CarSpecsUIContext";

const prepareFilter = (queryParams, values) => {
  const { status, type, searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by status
  // Filter by type
  const type1 = type !== "" ? +type : 0;
  // Filter by all fields
  if (searchText) {
    if(type1===0) {
      filter.name = searchText;
    } else {
      filter._id = searchText;

    }
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function SpecTypesFilter({ listLoading }) {
  // Rapportcategories UI Context
  const carSpecsUIContext = useCarSpecsUIContext();
  const carSpecsUIProps = useMemo(() => {
    return {
      queryParams: carSpecsUIContext.queryParams,
      setQueryParams: carSpecsUIContext.setQueryParams,
    };
  }, [carSpecsUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(carSpecsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, carSpecsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      carSpecsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
    </>
  );
}
