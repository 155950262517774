export const RecommendationStatusCssClasses = ["info", "warning", "success", "danger"];
export const RecommendationStatusTitles = ["EN_ATTENTE", "EN_COURS", "RESOLU", "REFUSEE"];
export const RecommendationTypeCssClasses = ["success", "primary", ""];
export const RecommendationTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "date", order: "desc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];
export const initialFilter = {
  filter: {
    fullName: "",
    email: "",
    voiture: "",
    carburant: "",
    carosserie: "",
    cylindree: "",
    couleur: ""
  },
  sortOrder: "desc", // asc||desc
  sortField: "created_At",
  pageNumber: 1,
  pageSize: 10
};
