import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/config/configsActions";
import { ConfigEditDialogHeader } from "./ConfigEditDialogHeader";
import { ConfigEditForm } from "./ConfigEditForm";
import { useConfigsUIContext } from "../ConfigsUIContext";

export function ConfigEditDialog({ id, show, onHide }) {
  // ConfigsUI Context
  const configsUIContext = useConfigsUIContext();
  const configsUIProps = useMemo(() => {
    return {
      initConfig: configsUIContext.initConfig,
    };
  }, [configsUIContext]);

  // ConfigsRedux state
  const dispatch = useDispatch();
  const { actionsLoading, configForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.configs.actionsLoading,
      configForEdit: state.configs.configForEdit,
    }),
    shallowEqual
  );

  // server request for saving ad
  const saveConfig = (config) => {
      // server request for updating ad
      dispatch(actions.updateConfig(config)).then(() => onHide());
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <ConfigEditDialogHeader id={id} />
      <ConfigEditForm
        saveConfig={saveConfig}
        actionsLoading={actionsLoading}
        config={configForEdit || configsUIProps.initConfig}
        onHide={onHide}
      />
    </Modal>
  );
}
