/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, createContext, useState, useCallback } from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./SpecsUIHelper";


const SpecsUIContext = createContext();

export function useSpecsUIContext() {
  return useContext(SpecsUIContext);
}

export const SpecsUIConsumer = SpecsUIContext.Consumer;

export function SpecsUIProvider({ currentSpecTypeId, children,history }) {
  const [specTypeId, setSpecTypeId] = useState(currentSpecTypeId);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const [selectedId, setSelectedId] = useState(null);
  const [showEditSpecDialog, setShowEditSpecDialog] = useState(false);
  const initSpec = {
    id: undefined,
    specTypeId: specTypeId
  };
  useEffect(()=> {
    initSpec.specTypeId = currentSpecTypeId;
    setSpecTypeId(currentSpecTypeId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpecTypeId]);
  const openNewSpecDialog = () => {
    setSelectedId(undefined);
    setShowEditSpecDialog(true);
  };
  const openEditSpecDialog = id => {
    setSelectedId(id);
    setShowEditSpecDialog(true);
  };
  const closeEditSpecDialog = () => {
    setSelectedId(undefined);
    setShowEditSpecDialog(false);
  };
  const [showDeleteSpecDialog, setShowDeleteSpecDialog] = useState(false);
  const openDeleteSpecDialog = id => {
    setSelectedId(id);
    setShowDeleteSpecDialog(true);
  };
  const closeDeleteSpecDialog = () => {
    setSelectedId(undefined);
    setShowDeleteSpecDialog(false);
  };

  const [showDeleteSpecsDialog, setShowDeleteSpecsDialog] = useState(false);
  const openDeleteSpecsDialog = () => {
    setShowDeleteSpecsDialog(true);
  };
  const closeDeleteSpecsDialog = () => {
    setShowDeleteSpecsDialog(false);
  };

  const [showFetchSpecsDialog, setShowFetchSpecsDialog] = useState(false);
  const openFetchSpecsDialog = () => {
    setShowFetchSpecsDialog(true);
  };
  const closeFetchSpecsDialog = () => {
    setShowFetchSpecsDialog(false);
  };

  const openAddItemstoSpecPage= (_id) => {
    history.push(`/dash/questions_rapportcategories/questions/${_id}/add`);
  }

  const value = {
    ids,
    setIds,
    specTypeId,
    setSpecTypeId,
    queryParams,
    setQueryParams,
    initSpec,
    selectedId,
    showEditSpecDialog,
    openNewSpecDialog,    
    openEditSpecDialog,
    closeEditSpecDialog,
    showDeleteSpecDialog,
    openDeleteSpecDialog,
    closeDeleteSpecDialog,
    showDeleteSpecsDialog,
    openDeleteSpecsDialog,
    closeDeleteSpecsDialog,
    openFetchSpecsDialog,
    closeFetchSpecsDialog,
    showFetchSpecsDialog,
    openAddItemstoSpecPage
  };
  
  return (
    <SpecsUIContext.Provider value={value}>
      {children}
    </SpecsUIContext.Provider>
  );
}
