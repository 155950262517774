import React, { useMemo } from "react";
import { useConfigsUIContext } from "../ConfigsUIContext";

export function ConfigsGrouping() {
  // ConfigsUI Context
  const configsUIContext = useConfigsUIContext();
  const configsUIProps = useMemo(() => {
    return {
      ids: configsUIContext.ids,
      setIds: configsUIContext.setIds,
      openDeleteConfigsDialog:
        configsUIContext.openDeleteConfigsDialog,
      openFetchConfigsDialog: configsUIContext.openFetchConfigsDialog,
      openUpdateConfigsStatusDialog:
        configsUIContext.openUpdateConfigsStatusDialog,
    };
  }, [configsUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                  Les enregistrements sélectionnés comptent:
                  <b>{configsUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={configsUIProps.openDeleteConfigsDialog}
              >
                <i className="fa fa-sync-alt"></i>Tout supprimer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
