import React from "react";
import { Route } from "react-router-dom";
import { SpecTypesLoadingDialog } from "./specTypes-loading-dialog/SpecTypesLoadingDialog";
import { CarSpecsUIProvider } from "./CarSpecsUIContext";
import { CarSpecsCard } from "./CarSpecsCard";

export function CarSpecsPage({ history }) {
  const carSpecsUIEvents = {
    openAddSpecsDialog: (_id) => {
      history.push(`/dash/specs/${_id}`);
    }
  }

  return (
    <CarSpecsUIProvider carSpecsUIEvents={carSpecsUIEvents}>
      <SpecTypesLoadingDialog />
      <CarSpecsCard />
    </CarSpecsUIProvider>
  );
}
