import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { SpecTypesTable } from "./specType-table/SpecTypesTable";
import { useCarSpecsUIContext } from "./CarSpecsUIContext";
import { SpecTypesFilter } from "./specTypes-filter/SpecTypesFilter";
import { SpecTypesGrouping } from "./specTypes-grouping/SpecTypesGrouping";

export function CarSpecsCard() {
  const carSpecsUIContext = useCarSpecsUIContext();
  const carSpecsUIProps = useMemo(() => {
    return {
      ids: carSpecsUIContext.ids,
    };
  }, [carSpecsUIContext]);

  return (
    <Card>
      <CardHeader title="Car Specs">
      </CardHeader>
      <CardBody>
        <SpecTypesFilter />
        {carSpecsUIProps.ids.length > 0 && <SpecTypesGrouping />}
        <SpecTypesTable />
      </CardBody>
    </Card>
  );
}
