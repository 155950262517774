// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useRef, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../../../_metronic/_partials/controls";

import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { TextArea } from "../../../../../../_metronic/_partials/controls/forms/TextArea";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actionsModel from "../../../_redux/models/modelsActions";
import { useDropzone } from 'react-dropzone'

export function VoitureEditForm({
  saveVoiture,
  voiture,
  actionsLoading,
  onHide,
  specTypes,
  marks
}) {

  // VoituresRedux state
  const dispatch = useDispatch();
  const { models } = useSelector(
    (state) => ({
      models: state.models.entities
    }),
    shallowEqual
  );

  const [selectedMark, setSelectedMark] = useState('')
  useEffect(() => {
    if (selectedMark) {
      dispatch(actionsModel.fetchModels(
        {
          pageSize: 2000,
          pageNumber: 1
        },
        selectedMark
      ))
    }

  }, [selectedMark])

  const VoitureEditSchema = Yup.object().shape({
    title: Yup.string()
      .required("Le champ est obligatoire"),
    contact: Yup.string()
      .required("Le champ est obligatoire"),
    description: Yup.string()
      .required("Le champ est obligatoire"),
    price: Yup.number()
      .required("Le champ est obligatoire"),
    kilometrage: Yup.number()
      .required("Le champ est obligatoire"),
    year: Yup.number()
      .required("Le champ est obligatoire"),
    brand: Yup.string()
      .required("Le champ est obligatoire"),
    modele: Yup.string()
      .required("Le champ est obligatoire"),
    carburant: Yup.string()
      .required("Le champ est obligatoire"),
    cylindree: Yup.string()
      .required("Le champ est obligatoire"),
    carosserie: Yup.string()
      .required("Le champ est obligatoire"),
    color: Yup.string()
      .required("Le champ est obligatoire"),
    shifter: Yup.string()
      .required("Le champ est obligatoire"),
  });

  const [specs, setSpecs] = useState({
    Carburant: '',
    Carosserie: '',
    Cylindrée: '',
    Couleur: '',
    brand: '',
    model: '',
    Shifter: ''
  })

  const [files, setFiles] = useState([])
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles?.length) {
      setError(false)
      setFiles(previousFiles => [
        ...previousFiles,
        ...acceptedFiles.map(file =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      ])
    }
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': []
    },
    maxSize: 1024 * 10000,
    onDrop
  })
  const removeFile = name => {
    setFiles(files => files.filter(file => file.name !== name))
  }

  function convertToFileArray(data) {
    return data.map(async item => {
      if (item instanceof File) {
        return item;
      } else if (item.preview && item.preview.startsWith('http')) {
        const response = await fetch(item.preview);
        const blob = await response.blob();
        const file = new File([blob], item.name || 'unknown.jpg', { type: blob.type });
        file.preview = item.preview; // Add preview property to the File object
        return file;
      } else {
        return new File([], item.name || 'unknown.jpg', { type: 'application/octet-stream' });
      }
    });
  }

  useEffect(() => {
    if (voiture._id && files.length === 0) {
      const list = [];
      list.push({ preview: voiture.primaryImage, name: voiture.primaryImage })
      for (let img of voiture.secondaryImages)
        list.push({ preview: img, name: img })
      Promise.all(convertToFileArray(list)).then(resultArray => {
        setFiles(resultArray)
      });
    } else {
      setFiles([])
    }
  }, [voiture._id])

  const [error, setError] = useState(false)

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...voiture }}
        validationSchema={VoitureEditSchema}
        onSubmit={(values) => {
          if (!files.length) {
            setError(true)
          } else {
            if (!voiture._id)
              values.brand = marks.filter(mark => mark._id === values.brand)[0].name
            values.files = files
            saveVoiture(values)
          }
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          registerField,
          handleBlur,
          getFieldMeta,
          values,
          errors,
          touched,
        }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading ? (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              ) :
                <Form className="form form-label-right">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <Field
                          name="title"
                          component={Input}
                          placeholder="Title"
                          label="Title"
                          type="text"
                        />
                      </div>
                      <div className="col-md-6">
                        <Field
                          name="contact"
                          component={Input}
                          placeholder="Contact"
                          label="Contact"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="row tw-pt-4">
                      <div className="col">
                        <Field
                          name="description"
                          component={TextArea}
                          placeholder="Description"
                          label="Description"
                          type="textarea"
                          row="6"
                        />
                      </div>
                    </div>
                    <div className="row tw-pt-4">
                      <div className="col-md-4">
                        <Field
                          name="price"
                          component={Input}
                          placeholder="Price"
                          label="Price"
                          type="number"
                        />
                      </div>
                      <div className="col-md-4">
                        <Field
                          name="kilometrage"
                          component={Input}
                          placeholder="Kilometrage"
                          label="Kilometrage"
                          type="number"
                        />
                      </div>
                      <div className="col-md-4">
                        <Field
                          name="year"
                          component={Input}
                          placeholder="Year"
                          label="Year"
                          type="number"
                        />
                      </div>
                    </div>
                    {!voiture?._id && (
                      <>
                        <div className="row tw-pt-4">
                          <div className="col">
                            <Select
                              name="brand"
                              value={specs.brand}
                              label="Marque"
                              onChange={(e) => {
                                // const { name, value } = e.target.value;
                                setSpecs({
                                  ...specs,
                                  brand: e.target.value
                                });
                                setFieldValue(
                                  "brand",
                                  e.target.value
                                );
                                setSelectedMark(e.target.value);
                              }}
                            >
                              <option key={""} value={""} disabled hidden>
                                Selectionner une Marque
                              </option>
                              {marks?.map(mark => (
                                <option key={mark._id} value={mark._id}>
                                  {mark.name}
                                </option>
                              ))}
                            </Select>
                          </div>
                          <div className="col">
                            <Select
                              disabled={selectedMark === ''}
                              name="modele"
                              value={specs.model}
                              label="Model"
                              onChange={(e) => {
                                setSpecs({
                                  ...specs,
                                  model: e.target.value
                                });
                                setFieldValue(
                                  "modele",
                                  e.target.value
                                );
                              }}
                            >
                              <option key={""} value={""} disabled hidden>
                                Selectionner un Model
                              </option>
                              {models?.map(model => (
                                <option key={model._id} value={model.name}>
                                  {model.name}
                                </option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="row tw-pt-4">
                          <div className="col">
                            <Select
                              name="carburant"
                              value={specs.Carburant}
                              label="Type du Carburant"
                              onChange={(e) => {
                                setSpecs({
                                  ...specs,
                                  Carburant: e.target.value
                                });
                                setFieldValue(
                                  "carburant",
                                  e.target.value
                                );
                              }}
                            >
                              <option key={""} value={""} disabled hidden>
                                Selectionner un Carburant
                              </option>
                              {specTypes?.Carburant.map(spec => (
                                <option key={spec} value={spec}>
                                  {spec}
                                </option>
                              ))}
                            </Select>
                          </div>
                          <div className="col">
                            <Select
                              name="cylindree"
                              value={specs.Cylindrée}
                              label="Type du Cylindrée"
                              onChange={(e) => {
                                setSpecs({
                                  ...specs,
                                  Cylindrée: e.target.value
                                });
                                setFieldValue(
                                  "cylindree",
                                  e.target.value
                                );
                              }}
                            >
                              <option key={""} value={""} disabled hidden>
                                Selectionner un Cylindrée
                              </option>
                              {specTypes?.Cylindrée.map(spec => (
                                <option key={spec} value={spec}>
                                  {spec}
                                </option>
                              ))}
                            </Select>
                          </div>
                          <div className="col">
                            <Select
                              name="shifter"
                              value={specs.Shifter}
                              label="Type de la boite de Vitesse"
                              onChange={(e) => {
                                setSpecs({
                                  ...specs,
                                  Shifter: e.target.value
                                });
                                setFieldValue(
                                  "shifter",
                                  e.target.value
                                );
                              }}
                            >
                              <option key={""} value={""} disabled hidden>
                                Selectionner une Boite de Vitesse
                              </option>
                              {specTypes?.Shifter.map(spec => (
                                <option key={spec} value={spec}>
                                  {spec}
                                </option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="row tw-pt-4">
                          <div className="col">
                            <Select
                              name="carosserie"
                              value={specs.Carosserie}
                              label="Type du carosserie"
                              onChange={(e) => {
                                setSpecs({
                                  ...specs,
                                  Carosserie: e.target.value
                                });
                                setFieldValue(
                                  "carosserie",
                                  e.target.value
                                );
                              }}
                            >
                              <option key={""} value={""} disabled hidden>
                                Selectionner un Carosserie
                              </option>
                              {specTypes?.Carosserie.map(spec => (
                                <option key={spec} value={spec}>
                                  {spec}
                                </option>
                              ))}
                            </Select>
                          </div>
                          <div className="col">
                            <Select
                              name="color"
                              value={specs.Couleur}
                              label="Couleur"
                              onChange={(e) => {
                                setSpecs({
                                  ...specs,
                                  Couleur: e.target.value
                                });
                                setFieldValue(
                                  "color",
                                  e.target.value
                                );
                              }}
                            >
                              <option key={""} value={""} disabled hidden>
                                Selectionner un Couleur
                              </option>
                              {specTypes?.Couleur.map(spec => (
                                <option key={spec} value={spec}>
                                  {spec}
                                </option>
                              ))}
                            </Select>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="row tw-pt-4">
                      <div className="col">
                        <Field
                          name="reportId"
                          component={Input}
                          placeholder="Report Link"
                          label="Report Link"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className='container mt-10'>
                      <div
                        {...getRootProps({
                          className: 'rounded-lg border-4 border-dotted p-10 flex flex-col items-center cursor-pointer'
                        })}
                      >
                        <input {...getInputProps()} />
                        <div {...getRootProps()} className='flex flex-col items-center justify-center gap-4 text-center'>
                          {
                            isDragActive ?
                              <p className="tw-text-gray-500 tw-text-xl">Drop the files here ...</p> :
                              <p className="tw-text-gray-500 tw-text-xl">Drag 'n' drop some files here, or click to select files</p>
                          }
                        </div>
                      </div>
                      {error && <div className="tw-text-red-600 text-center">
                        At Least You Have to Select One Image
                      </div>}
                      {files.length !== 0 && <h3 className='title text-lg font-semibold text-neutral-600 mt-10 border-b pb-3'>
                        Accepted Files
                      </h3>}
                      <ul className='mt-6 tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 xl:tw-grid-cols-6 gap-10'>
                        {files.map(file => (
                          <li key={file.name} className='tw-relative rounded-md col'>
                            <img
                              src={file.preview}
                              alt={file.name}
                              className="tw-w-[100%] tw-h-[100%]"
                            />
                            <button
                              type='button'
                              className='w-7 h-7 border tw-rounded-full flex justify-center items-center tw-absolute tw-top-0 tw-right-0 !hover:tw-bg-white transition-colors'
                              onClick={() => removeFile(file.name)}
                            >
                              <img src="/media/svg/icons/Navigation/Close.svg" alt="Remove" className='w-5 h-5 !hover:tw-bg-white tw-transition-colors' />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </Form>
              }
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Annuler
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Enregistrer
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
