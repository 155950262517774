import * as requestFromServer from "./carSpecsCrud";
import { specsSlice, callTypes } from "./carSpecsSlice";

const { actions } = specsSlice;

export const findSpecTypes = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSpecTypes(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.specTypesFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find spec types";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSpecType = (_id) => (dispatch) => {
  if (!_id) {
    return dispatch(actions.specTypeFetched({ specTypeForEdit: undefined }));
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findSpecType(_id)
    .then((response) => {
      const spec = response.data;
      dispatch(actions.specTypeFetched({ specTypeForEdit: spec }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find spec type";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const findSpecs = (queryParams, specTypeId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchSpecs(queryParams, specTypeId)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.specsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find specs";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSpec = (_id) => (dispatch) => {
  if (!_id) {
    return dispatch(actions.specFetched({ specForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchSpec(_id)
    .then((response) => {
      const spec = response.data;
      dispatch(actions.specFetched({ specForEdit: spec }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find spec";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSpec = (specForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createSpec(specForCreation)
    .then((response) => {
      const spec = response.data;
      dispatch(actions.specCreated({ spec }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create spec";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSpec = (spec) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSpec(spec)
    .then(() => {
      dispatch(actions.specUpdated({ spec }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update spec";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSpec = (_id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSpec(_id)
    .then((response) => {
      dispatch(actions.specDeleted({ _id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't cancel spec";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSpecs = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSpecs(ids)
    .then(() => {
      dispatch(actions.specsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete specs";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
