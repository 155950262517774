/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {ContentRoute, Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import {routes} from "../routes/routes";
import ResetPage from "./modules/Auth/pages/ResetPage";

export function Routes() {
  const { isAuthorized,user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
        user: auth.user
    }),
    shallowEqual
  );

  return (
    <Switch>
      <Route path="/auth/reset/:token" component={ResetPage} />

        {!isAuthorized ? (
            /*Redirect to `/auth` when user is not authorized*/
            <>
                <Route>
                    <AuthPage>
                        {routes({
                            allows: user?.allows,
                            role: user?.roles,
                        })?.outLayout.map((val, key) => (
                            <ContentRoute {...val} key={key} />
                        ))}
                        <Redirect from="/auth" exact={true} to="/auth/login" />
                        {/* <Redirect to="/auth/login" /> */}
                    </AuthPage>
                </Route>
                <Redirect to="/auth/login" />
            </>
        ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage/>
        </Layout>
      )}
    </Switch>
  );
}
