import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import * as actions from "../../../_redux/partenaires/partenairesActions";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { usePartenairesUIContext } from "../PartenairesUIContext";

export function PartenaireDeleteDialog({ id, show, onHide }) {
  // partenairesUI Context
  const partenairesUIContext = usePartenairesUIContext();
  const partenairesUIProps = useMemo(() => {
    return {
      setIds: partenairesUIContext.setIds,
      queryParams: partenairesUIContext.queryParams,
    };
  }, [partenairesUIContext]);

  // partenairesRedux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.partenaires.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deletePartenaire = () => {
    // server request for deleting ad by id
    dispatch(actions.deletePartenaire(id)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchPartenaires(partenairesUIProps.queryParams));
      // clear selections list
      partenairesUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Supprimer le partenaire
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && <span>Souhaitez-vous supprimer ce partenaire?</span>}
        {isLoading && <span>Le partenaire est en train de suppression ...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            fermer
          </button>
          <> </>
          <button
            type="button"
            onClick={deletePartenaire}
            className="btn btn-danger btn-elevate"
          >
            Supprimer le partenaire
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
