// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import {
    ReservationCompletedStatusCssClasses,
    ReservationCompletedStatusTitles,
} from "../../ReservationsUIHelpers";

export function CompletedStatusColumnFormatter(cellContent, row) {
    let x = 0;

    if (row.completed === "0") {
        x = 0;
    } else if (row.completed === "1") {
        x = 1;
    }

    const getLabelCssClasses = () => {
        return `label label-lg label-light-${ReservationCompletedStatusCssClasses[x]
            } label-inline`;
    };
    return (
        <span className={getLabelCssClasses()}>
            {ReservationCompletedStatusTitles[x]}
        </span>
    );
}


