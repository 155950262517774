import React, {useMemo} from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../../_metronic/_partials/controls";

import { MatriculesFilter } from "./matricules-filter/MatriculesFilter";
import { MatriculesTable } from "./matricules-table/MatriculesTable";
// import { MatriculesGrouping } from "./matricules-grouping/MatriculesGrouping";
import { useMatriculesUIContext } from "./MatriculesUIContext";

export function MatriculesCard() {
  const matriculesUIContext = useMatriculesUIContext();
  const matriculesUIProps = useMemo(() => {
    return {
      ids: matriculesUIContext.ids,
      newMatriculeButtonClick: matriculesUIContext.newMatriculeButtonClick,
    };
  }, [matriculesUIContext]);

  return (
    <Card>
      <CardHeader title="Matricules">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={matriculesUIProps.newMatriculeButtonClick}
          >
            Nouveau Matricule
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <MatriculesFilter />
        {/* {matriculesUIProps.ids.length > 0 && <MatriculesGrouping />} */}
        <MatriculesTable />
      </CardBody>
    </Card>
  );
}
