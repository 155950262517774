import React from "react";
import { Route } from "react-router-dom";
import { ConfigsLoadingDialog } from "./configs-loading-dialog/ConfigsLoadingDialog";
import { ConfigEditDialog } from "./config-edit-dialog/ConfigEditDialog";
import { ConfigsUIProvider } from "./ConfigsUIContext";

import { ConfigsCard } from "./ConfigsCard";

export function ConfigsPage({ history }) {
  const configsUIEvents = {
    openEditConfigDialog: (_id) => {
      history.push(`/dash/configs/${_id}/edit`);
    },
  };

  return (
    <ConfigsUIProvider configsUIEvents={configsUIEvents}>
      <ConfigsLoadingDialog />
      <Route path="/dash/configs/:_id/edit">
        {({ history, match }) => (
          <ConfigEditDialog
            show={match != null}
            id={match && match.params._id}
            onHide={() => {
              history.push("/dash/configs");
            }}
          />
        )}
      </Route>
      <ConfigsCard />
    </ConfigsUIProvider>
  );
}
