import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { MetaPagesFilter } from "./metaPages-filter/MetaPagesFilter";
import { MetaPagesTable } from "./metaPages-table/MetaPagesTable";
import { MetaPagesGrouping } from "./metaPages-grouping/MetaPagesGrouping";
import { useMetaPagesUIContext } from "./MetaPagesUIContext";

export function MetaPagesCard() {
  const metaPagesUIContext = useMetaPagesUIContext();
  const metaPagesUIProps = useMemo(() => {
    return {
      ids: metaPagesUIContext.ids,
      newMetaPageButtonClick: metaPagesUIContext.newMetaPageButtonClick,
    };
  }, [metaPagesUIContext]);

  return (
    <Card>
      <CardHeader title="MetaPages">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={metaPagesUIProps.newMetaPageButtonClick}
          >
            Nouveau MetaPage
          </button>
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody>
        <MetaPagesFilter />
        {metaPagesUIProps.ids.length > 0 && <MetaPagesGrouping />}
        <MetaPagesTable />
      </CardBody>
    </Card>
  );
}
