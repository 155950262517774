import { createSlice } from "@reduxjs/toolkit";

const initialSpecState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  specTypeForEdit: undefined,
  lastError: null,
  specs: null,
  specsCount: 0,
  specForEdit: undefined,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const specsSlice = createSlice({
  name: "specs",
  initialState: initialSpecState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    specTypesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    specTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.specTypeForEdit = action.payload.specTypeForEdit;
      state.error = null;
    },
    specsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.specs = entities;
      state.specsCount = totalCount;
    },
    specFetched: (state, action) => {
      state.actionsLoading = false;
      state.specForEdit = action.payload.specForEdit;
      state.error = null;
    },
    specCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.specs.push(action.payload.spec);
    },
    specUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.specs = state.specs.map((entity) => {
        if (entity._id === action.payload.spec._id) {
          return action.payload.spec;
        }
        return entity;
      });
    },
    specDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.specs = state.specs;
    },
    specsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.specs = state.specs.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
  },
});
