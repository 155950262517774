import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/matricules/matriculesActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../MatriculesUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useMatriculesUIContext } from "../MatriculesUIContext";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";

export function MatriculesTable() {
  // MatriculesUI Context
  const matriculesUIContext = useMatriculesUIContext();
  const matriculesUIProps = useMemo(() => {
    return {
      ids: matriculesUIContext.ids,
      setIds: matriculesUIContext.setIds,
      queryParams: matriculesUIContext.queryParams,
      setQueryParams: matriculesUIContext.setQueryParams,
      openEditMatriculeDialog: matriculesUIContext.openEditMatriculeDialog,
      openDeleteMatriculeDialog: matriculesUIContext.openDeleteMatriculeDialog,
    };
  }, [matriculesUIContext]);

  // Getting current state of matricules list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.matricules }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // MatriculesRedux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    matriculesUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchMatricules(matriculesUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matriculesUIProps.queryParams, dispatch]);

  // Table columns
  const columns = [
    {
      dataField: "_id",
      text: "ID",
      sort: true,
      formatter: (cell) => (
        <div>
          <CopyToClipboard
            text={cell}
            onCopy={() => toast.success("ID Copied to Clipboard")}
          >
            <button className="text-primary font-bold">{cell}</button>
          </CopyToClipboard>
        </div>
      ),
    },
    {
      dataField: "code",
      text: "code",
      sort: true,
    },
    {
      dataField: "name",
      text: "name",
      sort: true,
    },
    {
      dataField: "part1",
      text: "Part 1",
      sort: true,
    },
    {
      dataField: "part2",
      text: "Part 2",
      sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditMatriculeDialog: matriculesUIProps.openEditMatriculeDialog,
        openDeleteMatriculeDialog: matriculesUIProps.openDeleteMatriculeDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: matriculesUIProps.queryParams.pageSize,
    page: matriculesUIProps.queryParams.pageNumber,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => (
          <Pagination isLoading={listLoading} paginationProps={paginationProps}>
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-vertical-center"
              bootstrap4
              remote
              keyField="_id"
              data={entities === null ? [] : entities}
              columns={columns}
              defaultSorted={uiHelpers.defaultSorted}
              onTableChange={getHandlerTableChange(
                matriculesUIProps.setQueryParams
              )}
              selectRow={getSelectRow({
                entities,
                ids: matriculesUIProps.ids,
                setIds: matriculesUIProps.setIds,
              })}
              {...paginationTableProps}
            >
              <PleaseWaitMessage entities={entities} />
              <NoRecordsFoundMessage entities={entities} />
            </BootstrapTable>
          </Pagination>
        )}
      </PaginationProvider>
    </>
  );
}
