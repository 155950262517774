import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";


export function findSpecTypes() {

  return axios.get(
    process.env.REACT_APP_BASE_URL + "/carSpecs/types"
  );
}

export function findSpecType(specTypeId) {

  return axios.get(
    process.env.REACT_APP_BASE_URL + "/carSpecs/types/" + specTypeId
  );
}

export function createSpec(spec) {
  return axios.post(
    process.env.REACT_APP_BASE_URL + `/carSpecs/spec`,
    spec
  );
}

export function deleteSpec(specId) {
  return axios.delete(process.env.REACT_APP_BASE_URL + `/carSpecs/spec/${specId}`);
}

export function deleteSpecs(ids) {
  const body = {
    ids: ids
  }
  return axios.delete(process.env.REACT_APP_BASE_URL + `/carSpecs/spec/ids`, {
    data: body
  });
}

export function fetchSpecs(queryParams, specTypeId) {
  return axios.post(process.env.REACT_APP_BASE_URL + `/carSpecs/spec/paginate/${specTypeId}`, queryParams);
}

export function fetchSpec(specTypeId) {
  return axios.get(process.env.REACT_APP_BASE_URL + `/carSpecs/spec/${specTypeId}`);
}

export function updateSpec(spec) {
  return axios.put(
    process.env.REACT_APP_BASE_URL + `/carSpecs/spec/${spec._id}`,
    spec
  );
}
