import React from "react";
import { Route } from "react-router-dom";
import { RecommendationsLoadingDialog } from "./recommendations-loading-dialog/RecommendationsLoadingDialog";
import { RecommendationsUIProvider } from "./RecommendationsContext";
import { RecommendationsCard } from "./RecommendationsCard";
import { RecommendationsUpdateStateDialog } from "./recommendations-update-status-dialog/RecommendationsUpdateStateDialog";
import { RecommendationFetchDetails } from "./recommendation-fetch-details/RecommendationFetchDetails";

export function RecommendationsPage({ history }) {
  const recommendationsUIEvents = {
    openUpdateRecommendationsStatusDialog: () => {
      history.push("/dash/recommendations/updateStatus");
    },
    openFetchRecommendationDetailsDialog: (id) => {
      history.push(`/dash/recommendations/${id}/details`);
    },
    
  }

  return (
    <RecommendationsUIProvider recommendationsUIEvents={recommendationsUIEvents}>
      <RecommendationsLoadingDialog />
      
      <Route path="/dash/recommendations/updateStatus">
        {({ history, match }) => (
          <RecommendationsUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/recommendations");
            }}
          />
        )}
      </Route>
      <Route path="/dash/recommendations/:_id/details">
        {({ history, match }) => (
          <RecommendationFetchDetails
            show={match != null}
            id={match && match.params._id}
            onHide={() => {
              history.push("/dash/recommendations");
            }}
          />
        )}
      </Route>
      <RecommendationsCard />
    </RecommendationsUIProvider>
  );
}
