import React, {useState, useEffect} from "react";
import { TagsInput } from "react-tag-input-component";

const TagInput = ({ name, value, onChange, placeholder }) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (value) {
      setTags(value);
    }
  }, [value]);

  const handleTagsChange = (newTags) => {
    setTags(newTags);
    onChange(name, newTags); 
  };

  return (
    <div>
      <TagsInput
        value={tags}
        onChange={handleTagsChange}
        name={name}
        placeHolder={placeholder}
        classNames="w-full p-10 rounded-md border border-gray-300 font-sans text-gray-700 placeholder-gray-50 focus:outline-none"
      />
    </div>
  );
};

export default TagInput;
