// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/config/configsActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../ConfigsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useConfigsUIContext } from "../ConfigsUIContext";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";

export function ConfigsTable() {
  // ConfigsUI Context
  const configsUIContext = useConfigsUIContext();
  const configsUIProps = useMemo(() => {
    return {
      ids: configsUIContext.ids,
      setIds: configsUIContext.setIds,
      queryParams: configsUIContext.queryParams,
      setQueryParams: configsUIContext.setQueryParams,
      openEditConfigDialog: configsUIContext.openEditConfigDialog,
      openDeleteConfigDialog: configsUIContext.openDeleteConfigDialog,
      openFetchConfigsDetailsDialog:
        configsUIContext.openFetchConfigsDetailsDialog,
    };
  }, [configsUIContext]);

  // Getting curret state of configs list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.configs }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // ConfigsRedux state
  const dispatch = useDispatch();
  const getValueActive = ({ ad, isActive }) => {
    ad = {
      ...ad,
      isActive,
    };
    
    dispatch(actions.updateConfig(ad));
  };
  useEffect(() => {
    // clear selections list
    configsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchConfig(configsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configsUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [ 
    {
      dataField: "tva",
      text: "TVA",
      sort: false,
      classes: "text-center",
      style: {
        width: "50%",
      },
      headerStyle: {
        textAlign: "center",
      },
      formatter: (cell) => `${cell} %`,
    },
    {
      dataField: "timbre",
      text: "TIMBRE",
      sort: false,
      classes: "text-center",
      style: {
        width: "50%",
      },
      headerStyle: {
        textAlign: "center",
      },
      formatter: (cell) => `${cell} dt`,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditConfigDialog: configsUIProps.openEditConfigDialog,
        getValueActive,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: configsUIProps.queryParams.pageSize,
    page: configsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
  
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  configsUIProps.setQueryParams
                )}
                
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
