import * as requestFromServer from "./recommendationsCrud";
import {recommendationsSlice, callTypes} from "./recommendationsSlice";

const {actions} = recommendationsSlice;

export const fetchRecommendations = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findRecommendations(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.recommendationsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find recommendations";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchRecommendation = (_id) => (dispatch) => {
  if (!_id) {
    return dispatch(actions.recommendationFetched({ recommendationForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getRecommendationById(_id)
    .then((response) => {
      const recommendation = response.data;
      dispatch(actions.recommendationFetched({ recommendationForEdit: recommendation }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find recommendation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateRecommendationsStatus = (ids, etat) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForRecommendations(ids, etat)
    .then(() => {
      dispatch(actions.recommendationsStatusUpdated({ ids, etat }));
    })
    .catch(error => {
      error.clientMessage = "Can't update recommendations status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
