import React, { useMemo } from "react";
import { usePartenairesUIContext } from "../PartenairesUIContext";

export function PartenairesGrouping() {
  // PartenairesUI Context
  const partenairesUIContext = usePartenairesUIContext();
  const partenairesUIProps = useMemo(() => {
    return {
      ids: partenairesUIContext.ids,
      setIds: partenairesUIContext.setIds,
      openFetchPartenairesDialog: partenairesUIContext.openFetchPartenairesDialog,
      openUpdatePartenairesStatusDialog:
        partenairesUIContext.openUpdatePartenairesStatusDialog,
    };
  }, [partenairesUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                  Les enregistrements sélectionnés comptent:
                  <b>{partenairesUIProps.ids.length}</b>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
