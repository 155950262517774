import React from "react";
import { Route } from "react-router-dom";
import { PartenairesLoadingDialog } from "./partenaires-loading-dialog/PartenairesLoadingDialog";
import { PartenaireDeleteDialog } from "./partenaire-delete-dialog/PartenaireDeleteDialog";
import { PartenairesUIProvider } from "./PartenairesUIContext";
import { PartenaireEditDialog } from "./partenaire-edit-dialog/PartenaireEditDialog";
import {PartenairesFetchDialog} from "./partenaires-fetch-dialog/PartenairesFetchDialog";
import { PartenairesCard } from "./PartenairesCard";


export function PartenairesPage({ history }) {
  const partenairesUIEvents = {
    newPartenaireButtonClick: () => {
      history.push("/dash/partenaires/new");
    },
    openEditPartenaireDialog: (_id) => {
      history.push(`/dash/partenaires/${_id}/edit`);
    },
    openDeletePartenaireDialog: (_id) => {
      history.push(`/dash/partenaires/${_id}/cancel`);
    },
  };

  return (
    <PartenairesUIProvider partenairesUIEvents={partenairesUIEvents}>
      <PartenairesLoadingDialog />
      <Route path="/dash/partenaires/new">
        {({ history, match }) => (
          <PartenaireEditDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/partenaires");
            }}
          />
        )}
      </Route>
      <Route path="/dash/partenaires/:_id/edit">
        {({ history, match }) => (
          <PartenaireEditDialog
            show={match != null}
            id={match && match.params._id}
            onHide={() => {
              history.push("/dash/partenaires");
            }}
          />
        )}
      </Route>
      <Route path="/dash/partenaires/:_id/cancel">
        {({ history, match }) => (
          <PartenaireDeleteDialog
            show={match != null}
            id={match && match.params._id}
            onHide={() => {
              history.push("/dash/partenaires");
            }}
          />
        )}
      </Route>
      <Route path="/dash/partenaires/fetch">
        {({ history, match }) => (
          <PartenairesFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/partenaires");
            }}
          />
        )}
      </Route>
      <PartenairesCard />
    </PartenairesUIProvider>
  );
}
