import React, { useMemo } from "react";
import { SpecsFilter } from "./SpecsFilter";
import { SpecsTable } from "./SpecsTable";
import { SpecsLoadingDialog } from "./SpecsLoadingDialog";
import { SpecsDeleteDialog } from "./SpecsDeleteDialog";
import { SpecDeleteDialog } from "./SpecDeleteDialog";
import { SpecsFetchDialog } from "./SpecsFetchDialog";
import { SpecsGrouping } from "./SpecsGrouping";
import { SpecEditDialog } from "./spec-edit-dialog/SpecEditDialog";
import { useSpecsUIContext } from "./SpecsUIContext";

export function Specs() {
  // Specs UI Context
  const specsUIContext = useSpecsUIContext();
  const specsUIProps = useMemo(() => {
    return { ids: specsUIContext.ids };
  }, [specsUIContext]);

  return (
    <>
      <SpecsLoadingDialog />
      <SpecEditDialog />
      <SpecDeleteDialog />
      <SpecsDeleteDialog />
      <SpecsFetchDialog />
      <div className="form margin-b-30">
        <SpecsFilter />
        {specsUIProps.ids.length > 0 && <SpecsGrouping />}
      </div>
      <SpecsTable />
    </>
  );
}
