import React, { useEffect, useState } from "react";
import { Image, Modal } from "react-bootstrap";

import { ModalProgressBar, Select } from "../../../../../../_metronic/_partials/controls";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/reservations/reservationsActions";
import { Formik } from "formik";

export function ReservationFetchDetails({ id, show, onHide }) {
  // Experts UI Context
  // Experts Redux state
  const dispatch = useDispatch();
  const { actionsLoading, reservationForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.reservations.actionsLoading,
      reservationForEdit: state.reservations.reservationForEdit,
    }),
    shallowEqual
  );

  const [completedStatus, SetCompletedStatus] = useState("")
  useEffect(() => {
    dispatch(actions.fetchReservation(id));
  }, [id, dispatch]);

  useEffect(() => {
    SetCompletedStatus(reservationForEdit?.completed)
  }, [reservationForEdit])


  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...reservationForEdit }}
      // validationSchema={VoitureEditSchema}
      onSubmit={(values) => {
        dispatch(actions.updateReservationsCompletedStatusById(id, values.completed));
      }}
    >
      {({
        handleSubmit,
        setFieldValue
      }) => (
        <Modal
          size="lg"
          show={show}
          onHide={onHide}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          {/*begin::Loading*/}
          {actionsLoading && <ModalProgressBar />}
          {/*end::Loading*/}

          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Reservation détails
            </Modal.Title>
          </Modal.Header>
          {reservationForEdit && (
            <Modal.Body>
              <div>
                <div className="tw-flex tw-flex-col items-center">
                  <div className="tw-container my-5">
                    <div className="tw-w-3/5 mx-auto text-center">
                      <Select
                        name="completed"
                        value={completedStatus}
                        label="Si la Mission est Complétement Traitée"
                        onChange={(e) => {
                          SetCompletedStatus(e.target.value)
                          setFieldValue(
                            "completed",
                            e.target.value
                          );
                          handleSubmit()
                        }}
                      >
                        <option key="0" value="0">
                          Non Traité
                        </option>
                        <option key="1" value="1">
                          Traité
                        </option>
                      </Select>
                    </div>
                  </div>
                  <div className=" tw-w-full tw-mt-2 tw-border-2 tw-border-gray-200 tw-flex tw-flex-row ">
                    <div className="text-center tw-w-full tw-text-gray-800 tw-bg-gray-300 tw-text-lg tw-border-r-2 tw-border-r-gray-200 whitespace-normal	">
                      Mission
                    </div>
                  </div>
                  <InfoUser label="ID" render={() => reservationForEdit._id} />
                  <InfoUser
                    label="Etat"
                    render={() =>
                      reservationForEdit.status
                    }
                  />
                  <InfoUser
                    label="Nom & Prénom"
                    render={() =>
                      reservationForEdit.fullName
                    }
                  />
                  <InfoUser
                    label="Tel"
                    render={() =>
                      reservationForEdit.phone
                    }
                  />
                  <InfoUser
                    label="Raison"
                    render={() =>
                      reservationForEdit.reason
                    }
                  />
                  <InfoUser
                    label="Voiture"
                    render={() =>
                      reservationForEdit.typeCar
                    }
                  />
                  <InfoUser
                    label="Prix"
                    render={() =>
                      reservationForEdit.price + " DT"
                    }
                  />
                  <InfoUser
                    label="Date"
                    render={() =>
                      new Date(reservationForEdit.date).toLocaleString('fr-FR')
                    }
                  />
                  <InfoUser
                    label="Payement"
                    render={() => {
                      if (reservationForEdit.onlinePayment) {
                        return `En Ligne - ${reservationForEdit.paid ? 'Payé' : 'Non Payé'}`;
                      } else {
                        return 'Sur Place';
                      }
                    }}
                  />
                  <div className=" tw-w-full tw-mt-2 tw-border-2 tw-border-gray-200 tw-flex tw-flex-row ">
                    <div className="text-center tw-w-full tw-text-gray-800 tw-bg-gray-300 tw-text-lg tw-border-r-2 tw-border-r-gray-200 whitespace-normal	">
                      Client
                    </div>
                  </div>
                  <InfoUser
                    label="Nom & Prénom"
                    render={() =>
                      reservationForEdit.clientId.fullName
                    }
                  />
                  <InfoUser
                    label="Email"
                    render={() =>
                      reservationForEdit.clientId.email
                    }
                  />
                  <InfoUser
                    label="Tel"
                    render={() =>
                      reservationForEdit.clientId.tel
                    }
                  />
                  <InfoUser
                    label="Adresse"
                    render={() =>
                      reservationForEdit.clientId.ville + " " + reservationForEdit.clientId.adresse
                    }
                  />

                  <div className=" tw-w-full tw-mt-2 tw-border-2 tw-border-gray-200 tw-flex tw-flex-row ">
                    <div className="text-center tw-w-full tw-text-gray-800 tw-bg-gray-300 tw-text-lg tw-border-r-2 tw-border-r-gray-200 whitespace-normal	">
                      Expert
                    </div>
                  </div>
                  <InfoUser
                    label="Nom & Prénom"
                    render={() =>
                      reservationForEdit.expertId.fullName
                    }
                  />
                  <InfoUser
                    label="Email"
                    render={() =>
                      reservationForEdit.expertId.email
                    }
                  />
                  <InfoUser
                    label="Tel"
                    render={() =>
                      reservationForEdit.expertId.tel
                    }
                  />
                  <InfoUser
                    label="Adresse"
                    render={() =>
                      reservationForEdit.expertId.ville + " " + reservationForEdit.expertId.adresse
                    }
                  />
                </div>
              </div>
            </Modal.Body>
          )}
          <Modal.Footer>
            <div>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Ok
              </button>
              <> </>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}

function InfoUser({ label, render }) {
  return (
    <div className=" tw-w-full tw-mt-2 tw-border-2 tw-border-gray-200 tw-flex tw-flex-row ">
      <div className="text-center tw-w-2/5 tw-text-gray-800 tw-bg-gray-100 tw-text-lg tw-border-r-2 tw-border-r-gray-200 whitespace-normal	">
        {label}
      </div>
      <div className="tw-pl-4 tw-w-3/5 max-w-4/5">{render ? render() : ""}</div>
    </div>
  );
}
