import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { RecommendationsFilter } from "./recommendations-filter/RecommendationsFilter";
import { RecommendationsTable } from "./recommendations-table/RecommendationsTable";
import { RecommendationsGrouping } from "./recommendations-grouping/RecommendationsGrouping";
import { useRecommendationsUIContext } from "./RecommendationsContext";

export function RecommendationsCard() {
  const recommendationsUIContext = useRecommendationsUIContext();
  const recommendationsUIProps = useMemo(() => {
    return {
      ids: recommendationsUIContext.ids,
    };
  }, [recommendationsUIContext]);

  return (
    <Card>
      <CardHeader title="Recommendations">
      </CardHeader>
      <CardBody>
        <RecommendationsFilter />
        {recommendationsUIProps.ids.length > 0 && <RecommendationsGrouping />}
        <RecommendationsTable />
      </CardBody>
    </Card>
  );
}
