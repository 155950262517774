import React from "react";
import { DashboardPage } from "../modules/Dash/pages/dashboard/DashboardPage";
//import { Demo1Dashboard } from "../../_metronic/_partials";

export function DashboardPage1() {
  return (
    <>
      <DashboardPage />
    </>
  );
}
