import React, { useState } from "react";
import axios from "axios";
import DownloadIcon from "./icons/DownloadIcon";
import ViewIcon from "./icons/ViewIcon";
import LoadingIcon from "./icons/LoadingIcon";

const Charger_Telecharger = ({ rapportId }) => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const handleLoad = async () => {
    try {
      setLoading(true);
      const pdf = await axios.get(
        process.env.REACT_APP_BASE_URL + `/rapport/pdf/${rapportId}`,
        {
          responseType: "arraybuffer",
        }
      );
      const blob = new Blob([pdf.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      setUrl(url);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Rapport.pdf"); // Change the filename if needed
    document.body.appendChild(link);
    link.click();
  };
  const windowName = "Rapport de diagnostic";
  const windowFeatures =
    "menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes,width=1000,height=800,top=100,left=400";
  const viewPdf = () => {
    window.open(url, windowName, windowFeatures);
  };
  return (
    <div className="flex md:items-center py-2 justify-between flex-col md:flex-row ">
      {url !== "" ? (
        <>
          <button
            onClick={handleDownload}
            className="pr-5 self-center"
            // className="flex items-center space-x-3 justify-center w-full xs:w-56 py-3 rounded-lg"
          >
            <DownloadIcon className="tw-h-6 tw-w-6 tw-text-blue-600" />
          </button>
          <button onClick={viewPdf} className="pl-5 self-center	">
            <ViewIcon className="tw-h-6 tw-w-6 tw-text-blue-600"></ViewIcon>
          </button>
        </>
      ) : (
        <div className="ml-auto grid gap-5 w-full xs:w-auto">
          <button
            disabled={loading}
            onClick={handleLoad}
            className="flex items-center space-x-3 justify-center w-full xs:w-56 py-3 rounded-lg font-rubik"
            style={{
              boxShadow: "0px 7px 34px rgba(78, 173, 255, 0.3)",
            }}
          >
            {loading ? (
              <LoadingIcon className="tw-h-6 tw-w-6 tw-text-blue-600" />
            ) : (
              <span>CHARGER RAPPORT</span>
            )}
          </button>
        </div>
      )}
    </div>
  );
};
export default Charger_Telecharger;
