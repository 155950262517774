import * as requestFromServer from "./partenairesCrud";
import { partenairesSlice, callTypes } from "./partenairesSlice";

const { actions } = partenairesSlice;

export const fetchPartenaires = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPartenaires(queryParams)
    .then((response) => {
      
      const { totalCount, entities } = response.data;
      dispatch(actions.partenairesFetched({ totalCount, entities }));
    })
    .catch((error) => {
      
      error.clientMessage = "Can't find partenaires";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createPartenaire = (partenaire) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createPartenaire(partenaire)
    .then((response) => {
      const partenaire = response.data;
      dispatch(actions.partenaireCreated({ partenaire }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create partenaire";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchPartenaire = (_id) => (dispatch) => {
  if (!_id) {
    return dispatch(actions.partenaireFetched({ partenaireForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getPartenaireById(_id)
    .then((response) => {
      const partenaire = response.data;
      dispatch(actions.partenaireFetched({ partenaireForEdit: partenaire }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find partenaire";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deletePartenaire = (_id) => (dispatch) => {
  

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deletePartenaire(_id)
    .then((response) => {
      dispatch(actions.partenaireDeleted({ _id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete partenaire";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updatePartenaire = (partenaire) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updatePartenaire(partenaire)
    .then((response) => {
      const partenaire = response.data;
    
      dispatch(actions.partenaireUpdated({ partenaire }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update partenaire";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

