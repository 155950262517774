import React, { useEffect, useState } from "react";
import { Image, Modal } from "react-bootstrap";

import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/recommendations/recommendationsActions";
import EyeIcon from "../../../../../../_metronic/_partials/controls/forms/img/EyeIcon";
import DocViewer from "./DocViewer";

export function RecommendationFetchDetails({ id, show, onHide }) {
  // Experts UI Context
  // Experts Redux state
  const dispatch = useDispatch();
  const { actionsLoading, recommendationForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.recommendations.actionsLoading,
      recommendationForEdit: state.recommendations.recommendationForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Expert by id
    dispatch(actions.fetchRecommendation(id));
  }, [id, dispatch]);


  const [showModal, setShowModal] = useState(false);
  const [showModalImage, setShowModalImage] = useState(false);
  const [link, setLink] = useState("");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Initial window width

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <>
      <DocViewer
        lien={link}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {/*begin::Loading*/}
        {actionsLoading && <ModalProgressBar />}
        {/*end::Loading*/}

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Recommendation détails
          </Modal.Title>
        </Modal.Header>
        {recommendationForEdit && (
          <Modal.Body>
            <div>
              <div className="tw-flex tw-flex-col items-center">
                <InfoUser label="ID" render={() => recommendationForEdit._id} />
                <InfoUser
                  label="Nom & Prénom"
                  render={() =>
                    recommendationForEdit.fullName
                  }
                />
                <InfoUser
                  label="Email"
                  render={() => <div className="whitespace-normal sm-md:whitespace-nowrap">
                    {innerWidth < 353 ? recommendationForEdit.email.split('@').join('@ ') : recommendationForEdit.email}
                  </div>
                  } />
                  <InfoUser
                  label="Télephone"
                  render={() =>
                    recommendationForEdit.tel
                  }
                />
                <InfoUser
                  label="Voiture"
                  render={() =>
                    recommendationForEdit.voiture
                  }
                />
                <InfoUser
                  label="Carburant"
                  render={() =>
                    recommendationForEdit.carburant
                  }
                />
                <InfoUser
                  label="Carosserie"
                  render={() =>
                    recommendationForEdit.carosserie
                  }
                />
                <InfoUser
                  label="Cylindrée"
                  render={() =>
                    recommendationForEdit.cylindree
                  }
                />
                <InfoUser
                  label="Boite de Vitesse"
                  render={() =>
                    recommendationForEdit.shifter
                  }
                />
                <InfoUser
                  label="Couleur"
                  render={() =>
                    recommendationForEdit.couleur
                  }
                />
                <InfoUser
                  label="Budget"
                  render={() =>
                    recommendationForEdit.minBudget + " - " + recommendationForEdit.maxBudget + " DT"
                  }
                />
                <InfoUser
                  label="Kilometrage"
                  render={() =>
                    recommendationForEdit.maxKilometrage + " KM"
                  }
                />
                <InfoUser
                  label="Année"
                  render={() =>
                    recommendationForEdit.minAnnee + " - " + recommendationForEdit.maxAnnee
                  }
                />
                <InfoUser
                  label="Création Date"
                  render={() =>
                    new Date(recommendationForEdit.created_At).toLocaleString('fr-FR')
                  }
                />
              </div>
            </div>
          </Modal.Body>
        )}
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              Ok
            </button>
            <> </>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function InfoUser({ label, render }) {
  return (
    <div className=" tw-w-full tw-mt-2 tw-border-2 tw-border-gray-200 tw-flex tw-flex-row ">
      <div className="text-center tw-w-2/5 tw-text-gray-800 tw-bg-gray-100 tw-text-lg tw-border-r-2 tw-border-r-gray-200 whitespace-normal	">
        {label}
      </div>
      <div className="tw-pl-4 tw-w-3/5 max-w-4/5">{render ? render() : ""}</div>
    </div>
  );
}
