import React, { useMemo } from "react";
import { useVoituresUIContext } from "../VoituresUIContext";

export function VoituresGrouping() {
  // VoituresUI Context
  const voituresUIContext = useVoituresUIContext();
  const voituresUIProps = useMemo(() => {
    return {
      ids: voituresUIContext.ids,
      setIds: voituresUIContext.setIds,
      openDeleteVoituresDialog:
        voituresUIContext.openDeleteVoituresDialog,
      openFetchVoituresDialog: voituresUIContext.openFetchVoituresDialog,
      openUpdateVoituresStatusDialog:
        voituresUIContext.openUpdateVoituresStatusDialog,
    };
  }, [voituresUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                  Les enregistrements sélectionnés comptent:
                  <b>{voituresUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={voituresUIProps.openDeleteVoituresDialog}
              >
                <i className="fa fa-sync-alt"></i>Tout supprimer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
