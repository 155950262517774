import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./RecommendationsUIHelpers";

const RecommendationsUIContext = createContext();

export function useRecommendationsUIContext() {
  return useContext(RecommendationsUIContext);
}

export const RecommendationsUIConsumer = RecommendationsUIContext.Consumer;

export function RecommendationsUIProvider({recommendationsUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initRecommendation = {
    _id: undefined,
    username: "",
    referCode: "",
    recommendationName:"",
    device: "",
    geo: "",
    ipSignup: "",
    points: 0,
    createdAt: "",
    isActive: "",
    provision: 0,
    isVerified: 1
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initRecommendation,
    newRecommendationButtonClick: recommendationsUIEvents.newRecommendationButtonClick,
    openEditRecommendationDialog: recommendationsUIEvents.openEditRecommendationDialog,
    openDeleteRecommendationDialog: recommendationsUIEvents.openDeleteRecommendationDialog,
    openDeleteRecommendationsDialog: recommendationsUIEvents.openDeleteRecommendationsDialog,
    openFetchRecommendationsDialog: recommendationsUIEvents.openFetchRecommendationsDialog,
    openUpdateRecommendationsStatusDialog: recommendationsUIEvents.openUpdateRecommendationsStatusDialog,
    openFetchRecommendationsDetailsDialog: recommendationsUIEvents.openFetchRecommendationsDetailsDialog,
    openFetchRecommendationDetailsDialog: recommendationsUIEvents.openFetchRecommendationDetailsDialog
  };

  return <RecommendationsUIContext.Provider value={value}>{children}</RecommendationsUIContext.Provider>;
}