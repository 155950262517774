import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthGuard = ({ component: Component, page, ...rest }) => {
  const { isAuthorized, user } = useSelector(({ auth }) => ({
    isAuthorized: auth.user != null,
    user: auth.user
  }));

  const isPageAllowed = user?.allows?.menus?.[page] === true || user?.allows?.configs?.[page] === true;

  return (
    <Route
      {...rest}
      render={(props) =>
        isPageAllowed || user.role === "ADMIN" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default AuthGuard;
