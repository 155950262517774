import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./VoituresUIHelpers";

const VoituresUIContext = createContext();

export function useVoituresUIContext() {
  return useContext(VoituresUIContext);
}

export const PacksUIConsumer = VoituresUIContext.Consumer;

export function VoituresUIProvider({ voituresUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initVoiture = {
    _id: undefined,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initVoiture,
    newVoitureButtonClick: voituresUIEvents.newVoitureButtonClick,
    openEditVoitureDialog: voituresUIEvents.openEditVoitureDialog,
    openDeleteVoitureDialog: voituresUIEvents.openDeleteVoitureDialog,
    openDeleteVoituresDialog: voituresUIEvents.openDeleteVoituresDialog,
    openFetchVoituresDialog: voituresUIEvents.openFetchVoituresDialog,
    openUpdateVoituresStatusDialog:
      voituresUIEvents.openUpdateVoituresStatusDialog,
    openFetchVoituresDetailsDialog:
      voituresUIEvents.openFetchVoituresDetailsDialog,
  };

  return (
    <VoituresUIContext.Provider value={value}>
      {children}
    </VoituresUIContext.Provider>
  );
}
