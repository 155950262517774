// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as actions from "../../../_redux/carSpecs/carSpecsActions";
import { ActionsColumnFormatter } from "./column-formatters/ActionsColumnFormatter";
import * as uiHelpers from "./SpecsUIHelper";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import { useSpecsUIContext } from "./SpecsUIContext";
import { ChoicesColumnFormatter } from "./column-formatters/IconColumnFormatter";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";

export function SpecsTable() {
  // Specs UI Context
  const specUIContext = useSpecsUIContext();
  const specsUIProps = useMemo(() => {
    return {
      ids: specUIContext.ids,
      setIds: specUIContext.setIds,
      queryParams: specUIContext.queryParams,
      setQueryParams: specUIContext.setQueryParams,
      specTypeId: specUIContext.specTypeId,
      openEditSpecDialog: specUIContext.openEditSpecDialog,
      openAddItemstoSpecPage: specUIContext.openAddItemstoSpecPage,
      openDeleteSpecDialog: specUIContext.openDeleteSpecDialog,
    };
  }, [specUIContext]);

  // Getting curret state of questions_rapportcategorys list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.specs }),
    shallowEqual
  );
  const { specsCount, specs, listLoading } = currentState;
  const dispatch = useDispatch();
  useEffect(() => {
    specsUIProps.setIds([]);
    dispatch(
      actions.findSpecs(
        specsUIProps.queryParams,
        specsUIProps.specTypeId
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specsUIProps.queryParams, dispatch, specsUIProps.specTypeId]);
  const columns = [
    {
      dataField: "_id",
      text: "ID",
      sort: true,
      formatter: (cell) => {
        return (
          <div>
            <CopyToClipboard
              text={cell}
              onCopy={() => toast.success("ID Copied to Clipboard")}
            >
              <button className="text-primary font-bold">_id</button>
            </CopyToClipboard>
          </div>
        );
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: false,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditSpecDialog: specsUIProps.openEditSpecDialog,
        openDeleteSpecDialog: specsUIProps.openDeleteSpecDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: specsCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: specsUIProps.queryParams.pageSize,
    page: specsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center"
                bordered={false}
                bootstrap4
                remote
                keyField="_id"
                data={specs === null ? [] : specs}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  specsUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities: specs,
                  ids: specsUIProps.ids,
                  setIds: specsUIProps.setIds,
                })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={specs} />
                <NoRecordsFoundMessage entities={specs} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
