import React from "react";
import ClockIcon from "./icons/ClockIcon";
import ErrorIcon from "./icons/ErrorIcon";
import Charger_Telecharger from "./Charger_Telecharger";

export function LinkColumnFormatter(cellContent, row) {
  let x;

  if (row.etat === "en attente" || row.etat === "EN_COURS") {
    x = <ClockIcon className="tw-h-6 tw-w-6 tw-text-purple-600" />;
  } else if (row.etat === "COMPLETEE" && row.reservation[0].rapportId) {
    x = (
      // <a target="_blank" rel="noopener noreferrer" href={row.link}>
      //   <DownloadIcon className="tw-h-6 tw-w-6 tw-text-blue-600" />
      // </a>
      <Charger_Telecharger
        rapportId={row.reservation[0].rapportId}
      ></Charger_Telecharger>
    );
  } else if (row.etat === "échoué" || row.etat === "annulé") {
    x = <ErrorIcon className="tw-h-6 tw-w-6 tw-text-red-600" />;
  }
  return <>{x}</>;
}
