import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useConfigsUIContext } from "../ConfigsUIContext";

const prepareFilter = (queryParams, values) => {
  const { status, searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by status
  filter.status = status !== "" ? +status : undefined;
  // Filter by type
  // Filter by all fields
  if (searchText) {
    filter._id = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function ConfigsFilter({ listLoading }) {
  // ConfigsUI Context
  const configsUIContext = useConfigsUIContext();
  const configsUIProps = useMemo(() => {
    return {
      queryParams: configsUIContext.queryParams,
      setQueryParams: configsUIContext.setQueryParams,
    };
  }, [configsUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(configsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, configsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      configsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
    </>
  );
}
