import React from "react";
import { Route } from "react-router-dom";
import { MetaPagesLoadingDialog } from "./metaPages-loading-dialog/MetaPagesLoadingDialog";
import { MetaPageEditDialog } from "./metaPage-edit-dialog/MetaPageEditDialog";
import { MetaPagesFetchDialog } from "./metaPages-fetch-dialog/MetaPagesFetchDialog";
import { MetaPageDeleteDialog } from "./metaPage-delete-dialog/MetaPageDeleteDialog";
import { MetaPagesUIProvider } from "./MetaPagesUIContext";

import { MetaPagesCard } from "./MetaPagesCard";
import { MetaPagesDeleteDialog } from "./metaPages-delete-dialog/MetaPagesDeleteDialog";

export function MetaPagesPage({ history }) {
  const metaPagesUIEvents = {
    newMetaPageButtonClick: () => {
      history.push("/dash/metaPages/new");
    },
    openEditMetaPageDialog: (_id) => {
      history.push(`/dash/metaPages/${_id}/edit`);
    },
    openDeleteMetaPageDialog: (_id) => {
      history.push(`/dash/metaPages/${_id}/cancel`);
    },
    openDeleteMetaPagesDialog: () => {
      history.push(`/dash/metaPages/deleteMetaPages`);
    },
  };

  return (
    <MetaPagesUIProvider metaPagesUIEvents={metaPagesUIEvents}>
      <MetaPagesLoadingDialog />
      <Route path="/dash/metaPages/new">
        {({ history, match }) => (
          <MetaPageEditDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/metaPages");
            }}
          />
        )}
      </Route>
      <Route path="/dash/metaPages/:_id/edit">
        {({ history, match }) => (
          <MetaPageEditDialog
            show={match != null}
            id={match && match.params._id}
            onHide={() => {
              history.push("/dash/metaPages");
            }}
          />
        )}
      </Route>
      <Route path="/dash/metaPages/:_id/cancel">
        {({ history, match }) => (
          <MetaPageDeleteDialog
            show={match != null}
            id={match && match.params._id}
            onHide={() => {
              history.push("/dash/metaPages");
            }}
          />
        )}
      </Route>
      <Route path="/dash/metaPages/fetch">
        {({ history, match }) => (
          <MetaPagesFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/metaPages");
            }}
          />
        )}
      </Route>
      <Route path="/dash/metaPages/deleteMetaPages">
        {({ history, match }) => (
          <MetaPagesDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/metaPages");
            }}
          />
        )}
      </Route>
      <MetaPagesCard />
    </MetaPagesUIProvider>
  );
}
