import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { MetaPageStatusCssClasses } from "../MetaPagesUIHelpers";
import { useMetaPagesUIContext } from "../MetaPagesUIContext";

const selectedMetaPages = (entities, ids) => {
  const _metaPages = [];
  ids.forEach((id) => {
    const metaPage = entities.find((el) => el.id === id);
    if (metaPage) {
      _metaPages.push(metaPage);
    }
  });
  return _metaPages;
};

export function MetaPagesFetchDialog({ show, onHide }) {
  // MetaPagesUI Context
  const metaPagesUIContext = useMetaPagesUIContext();
  const metaPagesUIProps = useMemo(() => {
    return {
      ids: metaPagesUIContext.ids,
    };
  }, [metaPagesUIContext]);

  // MetaPagesRedux state
  const { metaPages } = useSelector(
    (state) => ({
      metaPages: selectedMetaPages(
        state.metaPages.entities,
        metaPagesUIProps.ids
      ),
    }),
    shallowEqual
  );

  // if metaPages weren't selected we should close modal
  useEffect(() => {
    if (!metaPagesUIProps.ids || metaPagesUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaPagesUIProps.ids]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Fetch selected elements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="timeline timeline-5 mt-3">
          {metaPages.map((metaPage) => (
            <div
              className="timeline-item align-items-start"
              key={`id${metaPage.id}`}
            >
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3" />
              <div className="timeline-badge">
                <i
                  className={`fa fa-genderless text-${
                    MetaPageStatusCssClasses[metaPage.isActive]
                  } icon-xxl`}
                />
              </div>
              <div className="timeline-content text-dark-50 mr-5">
                <span
                  className={`label label-lg label-light-${
                    MetaPageStatusCssClasses[metaPage.isActive]
                  } label-inline`}
                >
                  ID: {metaPage.id}
                </span>
                <span className="ml-3">{metaPage.body}</span>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-primary btn-elevate"
          >
            Ok
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
