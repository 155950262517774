import { createSlice } from "@reduxjs/toolkit";

const initialMetaPageState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  metaPageForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const metaPagesSlice = createSlice({
  name: "metaPages",
  initialState: initialMetaPageState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getMetaPageById
    metaPageFetched: (state, action) => {
      state.actionsLoading = false;
      state.metaPageForEdit = action.payload.metaPageForEdit;
      state.error = null;
    },
    // findMetaPages
    metaPagesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    metaPageCreated: (state, action) => {
      
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.metaPage);
    },
    // updateMetaPage
    metaPageUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity._id === action.payload.metaPage._id) {
          return action.payload.metaPage;
        }
        return entity;
      });
    },
    // deleteMetaPage
    metaPageDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities;
    },
    // deleteMetaPages
    metaPagesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
  },
});
