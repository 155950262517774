import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../../_metronic/_partials/controls";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

// Validation schema for MatriculeEditForm
const MatriculeEditSchema = Yup.object().shape({
  code: Yup.string().required("Code is required"),
  name: Yup.string().required("Name is required"),
  part1: Yup.string().required("Part 1 is required"),
  part2: Yup.string().required("Part 2 is required"),
});

export function MatriculeEditForm({ saveMatricule, matricule, actionsLoading, onHide }) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={matricule}
        validationSchema={MatriculeEditSchema}
        onSubmit={(values) => {
          saveMatricule(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && <ModalProgressBar />}
              <Form className="form form-label-right">
                <div className="form-group">
                  {/* Code */}
                  <div className="row">
                    <Field
                      name="code"
                      component={Input}
                      placeholder="Code"
                      label="Code"
                      type="text"
                    />
                  </div>

                  {/* Name */}
                  <div className="row">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                      type="text"
                    />
                  </div>

                  {/* Part 1 */}
                  <div className="row">
                    <Field
                      name="part1"
                      component={Input}
                      placeholder="Part 1"
                      label="Part 1"
                      type="number"
                    />
                  </div>

                  {/* Part 2 */}
                  <div className="row">
                    <Field
                      name="part2"
                      component={Input}
                      placeholder="Part 2"
                      label="Part 2"
                      type="number"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Annuler
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Enregistrer
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
