import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/marketplace/voituresActions";
import * as actionsSpec from "../../../_redux/carSpecs/carSpecsActions";
import * as actionsMark from "../../../_redux/marks/marksActions";
import { VoitureEditDialogHeader } from "./VoitureEditDialogHeader";
import { VoitureEditForm } from "./VoitureEditForm";
import { useVoituresUIContext } from "../VoituresUIContext";

export function VoitureEditDialog({ id, show, onHide }) {
  // VoituresUI Context
  const voituresUIContext = useVoituresUIContext();
  const voituresUIProps = useMemo(() => {
    return {
      initVoiture: voituresUIContext.initVoiture,
    };
  }, [voituresUIContext]);

  // VoituresRedux state
  const dispatch = useDispatch();
  const { actionsLoading, voitureForEdit, specTypes, marks } = useSelector(
    (state) => ({
      actionsLoading: state.voitures.actionsLoading,
      voitureForEdit: state.voitures.voitureForEdit,
      specTypes: state.specs.entities?.reduce((result, specType) => {
        result[specType.name] = specType.specs.map(spec => spec.name);
        return result;
      }, {}),
      marks: state.marks.entities,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchVoiture(id));
    dispatch(actionsSpec.findSpecTypes())
    dispatch(actionsMark.fetchMarks({
      pageSize: 2000,
      pageNumber: 1
    }));
  }, [id, dispatch]);

  // server request for saving ad
  const saveVoiture = (voiture) => {
    let formData = new FormData();
    for (let key in voiture) {
      if (key !== 'files') {
        if (key === 'secondaryImages') {
          formData.append(key, JSON.stringify(voiture[key]));
        } else {
          formData.append(key, voiture[key]);
        }
      }
    }
    if (voiture.files && voiture.files.length > 0) {
      for (const file of voiture.files) {
        formData.append('files', file);
      }
    }
    if (!id) {
      formData.delete("_id");
      dispatch(actions.createVoiture(formData)).then(() => onHide());
    } else {
      dispatch(actions.updateVoiture(formData)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <VoitureEditDialogHeader id={id} />
      <VoitureEditForm
        saveVoiture={saveVoiture}
        actionsLoading={actionsLoading}
        voiture={voitureForEdit || voituresUIProps.initVoiture}
        onHide={onHide}
        specTypes={specTypes}
        marks={marks}
      />
    </Modal>
  );
}
