import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { usePartenairesUIContext } from "./PartenairesUIContext";
import { PartenairesFilter } from "./partenaires-filter/PartenairesFilter";
import { PartenairesTable } from "./partenaires-table/PartenairesTable";
import { PartenairesGrouping } from "./partenaires-grouping/PartenairesGrouping";


export function PartenairesCard() {
  const partenairesUIContext = usePartenairesUIContext();
  const partenairesUIProps = useMemo(() => {
    return {
      ids: partenairesUIContext.ids,
      newPartenaireButtonClick: partenairesUIContext.newPartenaireButtonClick,
    };
  }, [partenairesUIContext]);

  return (
    <Card>
      <CardHeader title="Partenaires">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={partenairesUIProps.newPartenaireButtonClick}
          >
            Nouveau Partenaire
          </button>
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody>
        <PartenairesFilter />
        {partenairesUIProps.ids.length > 0 && <PartenairesGrouping />}
        <PartenairesTable />
      </CardBody>
    </Card>
  );
}
