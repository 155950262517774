/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/carSpecs/carSpecsActions";
import { useSpecsUIContext } from "./SpecsUIContext";

export function SpecsDeleteDialog() {
  // Specs UI Context
  const specsUIContext = useSpecsUIContext();
  const specsUIProps = useMemo(() => {
    return {
      ids: specsUIContext.ids,
      setIds: specsUIContext.setIds,
      specTypeId: specsUIContext.specTypeId,
      queryParams: specsUIContext.queryParams,
      showDeleteSpecsDialog: specsUIContext.showDeleteSpecsDialog,
      closeDeleteSpecsDialog: specsUIContext.closeDeleteSpecsDialog,
    };
  }, [specsUIContext]);

  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.specs.actionsLoading }),
    shallowEqual
  );

  useEffect(() => {}, [isLoading, dispatch]);
  useEffect(() => {
    if (!specsUIProps.ids || specsUIProps.ids.length === 0) {
      specsUIProps.closeDeleteSpecsDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specsUIProps.ids]);

  const deleteSpecs = () => {
    dispatch(actions.deleteSpecs(specsUIProps.ids)).then(() => {
      dispatch(
        actions.findSpecs(
          specsUIProps.queryParams,
          specsUIProps.specTypeId
        )
      ).then(() => {
        specsUIProps.setIds([]);
        specsUIProps.closeDeleteSpecsDialog();
      });
    });
  };

  return (
    <Modal
      show={specsUIProps.showDeleteSpecsDialog}
      onHide={specsUIProps.closeDeleteSpecsDialog}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Specs Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to permanently delete selected spec?</span>
        )}
        {isLoading && <span>Specs are deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={specsUIProps.closeDeleteSpecsDialog}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteSpecs}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
