import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";

export function getVoitureById(voitureId) {
  return axios.get(
    process.env.REACT_APP_BASE_URL + `/car/${voitureId}`
  );
}

export function findVoitures(queryParams) {
  return axios.post(
    process.env.REACT_APP_BASE_URL + "/car/paginate",
    JSON.stringify(queryParams)
  );
}

export function updateVoiture(voiture) {
  return axios.put(
    process.env.REACT_APP_BASE_URL + `/car/${voiture.get("_id")}`,
    voiture, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
}

export function createVoiture(voiture) {
  return axios.post(
    process.env.REACT_APP_BASE_URL + `/car`,
    voiture, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
}

export function deleteVoiture(voitureId) {
  return axios.delete(
    process.env.REACT_APP_BASE_URL + `/car/${voitureId}`
  );
}

export function deleteVoitures(ids) {
  return axios.delete(process.env.REACT_APP_BASE_URL + `/car`, {
    data: { ids: ids }
  });
}
