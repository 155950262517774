import React, { useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { shallowEqual } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { MatriculeEditForm } from "./MatriculeEditForm";
import { fetchMatricule, createMatricule, updateMatricule } from "../../../_redux/matricules/matriculesActions";
import { useMatriculesUIContext } from "../MatriculesUIContext";

export function MatriculeEditDialog({ id, show, onHide }) {
  // Matricules UI Context
  const matriculesUIContext = useMatriculesUIContext();
  const matriculesUIProps = useMemo(() => {
    return {
      initMatricule: matriculesUIContext.initMatricule,
    };
  }, [matriculesUIContext]);

  // Matricules Redux state
  const dispatch = useDispatch();
  const { actionsLoading, matriculeForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.matricules.actionsLoading,
      matriculeForEdit: state.matricules.matriculeForEdit,
    }),
    shallowEqual
  );

  // Fetch matricule data when id changes
  useEffect(() => {
    if (id) {
      dispatch(fetchMatricule(id));
    } else {
      dispatch(fetchMatricule(matriculesUIProps.initMatricule.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]); // Only run the effect when id changes

  // Save matricule
  const saveMatricule = (matricule) => {
    if (!id) {
      dispatch(createMatricule(matricule)).then(onHide);
    } else {
      dispatch(updateMatricule(matricule)).then(onHide);
    }
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <MatriculeEditForm
        saveMatricule={saveMatricule}
        actionsLoading={actionsLoading}
        matricule={matriculeForEdit || matriculesUIProps.initMatricule}
        onHide={onHide}
      />
    </Modal>
  );
}
