import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { ConfigsTable } from "./configs-table/ConfigsTable";
import { useConfigsUIContext } from "./ConfigsUIContext";
import { ConfigsFilter } from "./configs-filter/ConfigsFilter"
import { ConfigsGrouping } from "./configs-grouping/ConfigsGrouping"

export function ConfigsCard() {
  const configsUIContext = useConfigsUIContext();
  const configsUIProps = useMemo(() => {
    return {
      ids: configsUIContext.ids,
      newConfigButtonClick: configsUIContext.newConfigButtonClick,
    };
  }, [configsUIContext]);

  return (
    <Card>
      <CardHeader title="Configs">
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody>
        <ConfigsFilter />
        {configsUIProps.ids.length > 0 && <ConfigsGrouping />}
        <ConfigsTable />
      </CardBody>
    </Card>
  );
}
