import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./MetaPagesUIHelpers";

const MetaPagesUIContext = createContext();

export function useMetaPagesUIContext() {
  return useContext(MetaPagesUIContext);
}

export const PacksUIConsumer = MetaPagesUIContext.Consumer;

export function MetaPagesUIProvider({ metaPagesUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initMetaPage = {
    _id: undefined,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initMetaPage,
    newMetaPageButtonClick: metaPagesUIEvents.newMetaPageButtonClick,
    openEditMetaPageDialog: metaPagesUIEvents.openEditMetaPageDialog,
    openDeleteMetaPageDialog: metaPagesUIEvents.openDeleteMetaPageDialog,
    openDeleteMetaPagesDialog: metaPagesUIEvents.openDeleteMetaPagesDialog,
    openFetchMetaPagesDialog: metaPagesUIEvents.openFetchMetaPagesDialog,
    openUpdateMetaPagesStatusDialog:
      metaPagesUIEvents.openUpdateMetaPagesStatusDialog,
    openFetchMetaPagesDetailsDialog:
      metaPagesUIEvents.openFetchMetaPagesDetailsDialog,
  };

  return (
    <MetaPagesUIContext.Provider value={value}>
      {children}
    </MetaPagesUIContext.Provider>
  );
}
