// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with spec first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
} from "../../../../../../../_metronic/_partials/controls";
// Validation schema

export function SpecEditForm({
  saveSpec,
  spec,
  actionsLoading,
  onHide,
  specTypeForEdit
}) {
  const SpecEditSchema = Yup.object().shape({
    name: Yup.string()
      .required("Le champ est obligatoire"),
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={spec}
        validationSchema={SpecEditSchema}
        onSubmit={(values) => {
          saveSpec({ ...values });
        }}
      >
        {({ handleSubmit, setFieldValue, setFieldError, errors }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading ? (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              ) :
                <Form className="form form-label-right">
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <Field
                        name="name"
                        component={Input}
                        placeholder="Spec"
                        label={specTypeForEdit.name}
                        type="text"
                      />
                    </div>
                  </div>

                </Form>
              }
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Annuler
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Enregistrer
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
