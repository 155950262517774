import React, {createContext, useContext, useState, useCallback} from "react";

import {isEqual, isFunction} from "lodash";

import {initialFilter} from "./MatriculesUIHelpers";

const MatriculesUIContext = createContext();

export function useMatriculesUIContext() {
  return useContext(MatriculesUIContext);
}

export const MatriculesUIConsumer = MatriculesUIContext.Consumer;

export function MatriculesUIProvider({matriculesUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initMatricule = {
    id: undefined,
    code : "",
    name : "",
    part1 : 0,
    part2 : 0,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initMatricule,
    newMatriculeButtonClick: matriculesUIEvents.newMatriculeButtonClick,
    openEditMatriculeDialog: matriculesUIEvents.openEditMatriculeDialog,
    openDeleteMatriculeDialog: matriculesUIEvents.openDeleteMatriculeDialog,
  };

  return <MatriculesUIContext.Provider value={value}>{children}</MatriculesUIContext.Provider>;
}
