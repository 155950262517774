import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/clients/clientsActions";

export function ClientFetchDetails({ id, show, onHide }) {
  const dispatch = useDispatch();
  const { actionsLoading, clientForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.clients.actionsLoading,
      clientForEdit: state.clients.clientForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchClient(id));
  }, [id, dispatch]);


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {/*begin::Loading*/}
        {actionsLoading && <ModalProgressBar />}
        {/*end::Loading*/}

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Client détails
          </Modal.Title>
        </Modal.Header>
        {clientForEdit && (
          <Modal.Body>
            <div>
              <div className="tw-flex tw-flex-col items-center">
                <img
                  src={clientForEdit.img ? clientForEdit.img : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"}
                  className="tw-w-2/3 tw-m-auto object-cover rounded-full border-2 border-gray-300"
                  alt="Expert's Image"
                />
                <InfoUser label="ID" render={() => clientForEdit._id} />
                <InfoUser
                  label="Nom & Prénom"
                  render={() => clientForEdit.fullName}
                />
                <InfoUser label="Email" render={() => { return (<div className="whitespace-normal sm-md:whitespace-nowrap">{innerWidth < 353 ? clientForEdit.email.split('@').join('@ ') : clientForEdit.email}</div>) }} />
                <InfoUser
                  label="Tel"
                  render={() => clientForEdit.tel
                  }
                />
                <InfoUser
                  label="Adresse"
                  render={() => clientForEdit.adresse
                  }
                />
                <InfoUser
                  label="Ville"
                  render={() => clientForEdit.ville
                  }
                />
                <InfoUser
                  label="Création Date"
                  render={() =>
                    new Date(clientForEdit.createdAt).toLocaleString()
                  }
                />
              </div>
            </div>
          </Modal.Body>
        )}
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              Ok
            </button>
            <> </>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function InfoUser({ label, render }) {
  return (
    <div className=" tw-w-full tw-mt-2 tw-border-2 tw-border-gray-200 tw-flex tw-flex-row ">
      <div className=" tw-w-2/5 tw-text-gray-800 tw-bg-gray-100 tw-text-lg tw-border-r-2 tw-border-r-gray-200 whitespace-normal	">
        {label}
      </div>
      <div className="tw-pl-4 tw-w-3/5 max-w-4/5">{render ? render() : ""}</div>
    </div>
  );
}
