import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useSpecsUIContext } from "./SpecsUIContext";

const selectedSpecs = (specs, ids) => {
  const _specs = [];
  ids.forEach((id) => {
    const spec = specs.find((el) => el.id === id);
    if (spec) {
      _specs.push(spec);
    }
  });
  return _specs;
};

export function SpecsFetchDialog() {
  // Specs UI Context
  const specsUIContext = useSpecsUIContext();
  const specsUIProps = useMemo(() => {
    return {
      ids: specsUIContext.ids,
      queryParams: specsUIContext.queryParams,
      showFetchSpecsDialog: specsUIContext.showFetchSpecsDialog,
      closeFetchSpecsDialog: specsUIContext.closeFetchSpecsDialog,
    };
  }, [specsUIContext]);

  const { specs } = useSelector(
    (state) => ({
      specs: selectedSpecs(state.specs.specs, specsUIProps.ids),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!specsUIProps.ids || specsUIProps.ids.length === 0) {
      specsUIProps.closeFetchSpecsDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specsUIProps.ids]);

  return (
    <Modal
      show={specsUIProps.showFetchSpecsDialog}
      onHide={specsUIProps.closeFetchSpecsDialog}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Fetch selected elements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="list-timeline list-timeline-skin-light padding-30">
          <div className="list-timeline-items">
            {specs.map((spec) => (
              <div className="list-timeline-item mb-3" key={spec.id}>
                <span className="list-timeline-text">
                  <span
                    className="label label-lg label-light-success label-inline"
                    style={{ width: "60px" }}
                  >
                    ID: {spec.id}
                  </span>{" "}
                  <span className="ml-5">{spec.name} </span>
                </span>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={specsUIProps.closeFetchSpecsDialog}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={specsUIProps.closeFetchSpecsDialog}
            className="btn btn-primary btn-elevate"
          >
            Ok
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
