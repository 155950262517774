import {createSlice} from "@reduxjs/toolkit";

const initialConfigsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  configForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const configsSlice = createSlice({
  name: "configs",
  initialState: initialConfigsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      

      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getConfigById
    configFetched: (state, action) => {
      state.actionsLoading = false;
      state.entities = [action.payload.entities]
      state.configForEdit = action.payload.entities;
      state.error = null;
    },
    // updateConfig
    configUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = [action.payload.entities]
      state.configForEdit = action.payload.entities;
    },
  }
});
