import * as requestFromServer from "./voituresCrud";
import { voituresSlice, callTypes } from "./voituresSlice";

const { actions } = voituresSlice;

export const fetchVoitures = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findVoitures(queryParams)
    .then((response) => {
      
      const { totalCount, entities } = response.data;
      dispatch(actions.voituresFetched({ totalCount, entities }));
    })
    .catch((error) => {
      
      error.clientMessage = "Can't find voitures";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createVoiture = (voiture) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createVoiture(voiture)
    .then((response) => {
      const voiture = response.data;
      dispatch(actions.voitureCreated({ voiture }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create voiture";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchVoiture = (_id) => (dispatch) => {
  if (!_id) {
    return dispatch(actions.voitureFetched({ voitureForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getVoitureById(_id)
    .then((response) => {
      const voiture = response.data;
      dispatch(actions.voitureFetched({ voitureForEdit: voiture }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find voiture";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteVoiture = (_id) => (dispatch) => {
  

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteVoiture(_id)
    .then((response) => {
      dispatch(actions.voitureDeleted({ _id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete voiture";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateVoiture = (voiture) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateVoiture(voiture)
    .then((response) => {
      const voiture = response.data;
      
      dispatch(actions.voitureUpdated({ voiture }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update voiture";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteVoitures = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteVoitures(ids)
    .then(() => {
      dispatch(actions.voituresDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete voitures";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
