import * as requestFromServer from "./reservationsCrud";
import {reservationsSlice, callTypes} from "./reservationsSlice";

const {actions} = reservationsSlice;

export const fetchReservations = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findReservations(queryParams)
    .then(response => {
      
      const { totalCount, entities } = response.data;
      dispatch(actions.reservationsFetched({ totalCount, entities }));
    })
    .catch(error => {
      
      error.clientMessage = "Can't find reservations";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchReservation = (_id) => (dispatch) => {
  if (!_id) {
    return dispatch(actions.reservationFetched({ reservationForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findReservation(_id)
    .then((response) => {
      const reservation = response.data;
      dispatch(actions.reservationFetched({ reservationForEdit: reservation }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find reservation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateReservationsStatus = (ids, etat) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForReservations(ids, etat)
    .then(() => {
      dispatch(actions.reservationsStatusUpdated({ ids, etat }));
    })
    .catch(error => {
      error.clientMessage = "Can't update reservations status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateReservationsCompletedStatusById = (id, completed) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateReservationsCompletedStatusById(id, completed)
    .then(() => {
      dispatch(actions.updateReservationsCompletedStatusById({ id, completed }));
    })
    .catch(error => {
      error.clientMessage = "Can't update reservations completed status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

