import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

export function PartenaireEditDialogHeader({ id }) {
  // PartenairesRedux state
  const { partenaireForEdit, actionsLoading } = useSelector(
    (state) => ({
      partenaireForEdit: state.partenaires.partenaireForEdit,
      actionsLoading: state.partenaires.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");
  
  // Title couting
  useEffect(() => {
    let _title = id ? "" : "Nouveau Partenaire";
    if (partenaireForEdit && id) {
      _title = `Modifier
      partenaire '${partenaireForEdit._id}'`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [partenaireForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
